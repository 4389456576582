import { Action, Location } from 'history';

import { ICustomHistory } from './history';
import { Logger } from './logger';
import { Store } from './store';

type IMatomoEventCategory = 'Onboarding' | 'Session' | 'Transfers' | 'Trading';
type IMatomoEventAction =
  // Onboarding
  | 'register'
  | 'verify_email'
  | 'request_verification_email'
  | 'request_kyc1'
  | 'request_kyc2'
  | 'submit_kyc2_code'
  | 'cancel_kyc2_request'

  // Session
  | 'login'
  | 'resume_session'
  | 'logout'
  | 'session_terminated'
  | 'request_reset_password'
  | 'complete_reset_password'

  // Transfers
  | 'reveal_deposit_address'
  | 'request_withdrawal'
  | 'confirm_withdrawal'
  | 'cancel_withdrawal'

  // Trading
  | 'buy_limit'
  | 'sell_limit'
  | 'buy_market'
  | 'sell_market'
  | 'buy_stop'
  | 'sell_stop'
  | 'cancel_order';

const CUSTOM_VARIABLE_NAMES = {
  session: 'Session',
};
const CUSTOM_VARIABLE_INDEXES = {
  session: 1,
};

export class Matomo {
  private logger: Logger;
  private issuedPaqMissingWarning = false;
  private lastDocumentTitle: string;

  constructor(
    private history: ICustomHistory,
    private store: Store,
    logger: Logger,
    private window: Window
  ) {
    this.logger = logger.prefixed('Matomo');
    this.lastDocumentTitle = this.store.getState().document.title;
  }

  private push(args: any[]) {
    const queue = (this.window as any)._paq;
    // Prevent memory leak
    if (queue && (queue.length === undefined || queue.length < 100)) {
      queue.push(args);
    }
  }

  private trackPageView(pathname?: string) {
    if (pathname) {
      this.push(['setCustomUrl', pathname]);
    }
    this.push(['trackPageView']);
    this.logger.verbose(`Page view tracked: ${pathname || this.window.location.pathname}`);
  }

  private trackDocumentTitle(documentTitle) {
    const fullTitle = `[${this.window.document.domain}] ${documentTitle}`;
    this.push(['setDocumentTitle', fullTitle]);
    this.logger.verbose(`Document title change tracked: ${fullTitle}`);
  }

  private onLocationChanged = (location: Location, action: Action) => {
    this.trackPageView(location.pathname);
  };

  private onStoreUpdate = () => {
    const documentTitle = this.store.getState().document.title;
    if (this.lastDocumentTitle !== documentTitle) {
      this.lastDocumentTitle = documentTitle;
      this.trackDocumentTitle(documentTitle);
    }
  };

  /**
   * https://developer.matomo.org/guides/tracking-javascript-guide#user-id
   */
  setUserId(userId: string | null) {
    if (userId) {
      this.push(['setUserId', userId]);
      this.logger.info(`Set user id: ${userId}`);
    } else {
      this.push(['resetUserId']);
      this.logger.info(`Clear user id`);
    }
  }

  /**
   * https://matomo.org/docs/custom-variables/#what-is-a-custom-variable
   * https://developer.matomo.org/guides/tracking-javascript-guide#custom-variables
   */
  setSession(session: string | null) {
    if (session) {
      this.push([
        'setCustomVariable',
        CUSTOM_VARIABLE_INDEXES.session,
        CUSTOM_VARIABLE_NAMES.session,
        'visit',
      ]);
      this.logger.info(`Set session: ${session}`);
    } else {
      this.push(['deleteCustomVariable', CUSTOM_VARIABLE_INDEXES.session, 'visit']);
      this.logger.info(`Clear session`);
    }
  }

  /**
   * https://matomo.org/docs/event-tracking/
   */
  sendEvent(category: IMatomoEventCategory, action: IMatomoEventAction, name?, value?: number) {
    const args: any[] = ['trackEvent', category, action];
    if (name !== undefined) {
      args.push(name);
    }
    if (value !== undefined) {
      args.push(value);
    }
    this.push(args);
    this.logger.info(
      `Send event: ${category} > ${action}${name ? ' ' + name : ''}${value ? ' ' + value : ''}`
    );
  }

  public initialize() {
    const matomoEnv = this.store.getState().env.matomo;

    if (!matomoEnv.url || !matomoEnv.siteId) {
      this.logger.error(`Analytics is not configured. It will be disabled.`);
      return;
    }

    this.logger.verbose(`Initializing analytics...`);

    // NOTE: Matomo will use global _paq array as initial source of its events
    //       It will then replace it with a proxy
    //       https://github.com/matomo-org/matomo/blob/6b075bbb17aea5d119a13274617d905a1fc7a808/js/piwik.js#L7613
    (this.window as any)._paq = [];

    this.history.listen(this.onLocationChanged);
    this.store.subscribe(this.onStoreUpdate);

    // Encode analytics requests
    this.push([
      'setCustomRequestProcessing',
      (req) => {
        // NOTE: We convert query to BASE64 to get around ad-blockers. The events will go through proxy which
        //       will restore the original query params.
        return btoa(req);
      },
    ]);

    if (matomoEnv.cookieDomain) {
      this.push(['setCookieDomain', matomoEnv.cookieDomain]);
    }

    if (matomoEnv.domains.length) {
      this.push(['setDomains', matomoEnv.domains]);
    }

    this.push(['enableCrossDomainLinking']);
    this.push(['enableLinkTracking']);

    this.trackPageView();

    this.push(['setTrackerUrl', matomoEnv.url + '/event-enc']);
    this.push(['setSiteId', matomoEnv.siteId]);

    const script = this.window.document.createElement('script');
    const firstSibling = this.window.document.getElementsByTagName('script')[0];
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = matomoEnv.url + '/xc-script.js';
    script.onload = () => {
      this.logger.info(`Analytics loaded`);
    };
    firstSibling.parentNode.insertBefore(script, firstSibling);
  }
}
