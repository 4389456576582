// Auto-generated by generate-client-js. Do not alter manually!

// *********************************************************************************************************************

export type IAdminPermission =
  | 'kyc_associate'
  | 'kyc_officer'
  | 'users'
  | 'security'
  | 'incentives'
  | 'trollbox'
  | 'finances'
  | 'audit'
  | 'developer'
  | 'managed'
  | 'transactions'
  | 'user_activity';

export type IBackendErrorName =
  | 'AddressUnavailableDueToDisabledDepositsError'
  | 'AttemptLimitReachedError'
  | 'BotNotFoundError'
  | 'CancelCompletedWithdrawalError'
  | 'CancelOrderRejectedError'
  | 'CancelTakenWithdrawalError'
  | 'CaptchaRequiredError'
  | 'CaptchaRequiredForRegistrationError'
  | 'CardPaymentDisabledForUserError'
  | 'CustomerNotFoundError'
  | 'CustomerRegistrationsAreDisabledError'
  | 'EmailAlreadyVerifiedError'
  | 'InsufficientFundsForLimitOrderError'
  | 'InsufficientFundsForManagedPayoutAmountError'
  | 'InsufficientFundsForMarketOrderError'
  | 'InsufficientFundsForSellOrderError'
  | 'InsufficientFundsForStopOrderError'
  | 'InsufficientFundsForWithdrawalError'
  | 'InsufficientQuantityInMarketError'
  | 'InvalidAddressError'
  | 'InvalidCaptchaTokenError'
  | 'InvalidEmailOrPasswordError'
  | 'InvalidEmailVerificationCodeError'
  | 'InvalidExistingPasswordError'
  | 'InvalidPhoneVerificationCodeError'
  | 'InvalidSecondFactorTokenError'
  | 'InvalidStopPriceError'
  | 'InvalidWithdrawalConfirmationLinkError'
  | 'KYCScreeningProviderError'
  | 'KYCVerificationProviderError'
  | 'ManagedBuyOrderMinPaymentQuantityError'
  | 'MaxCardPaymentQuantityError'
  | 'MaxWithdrawalQuantityError'
  | 'MinCardPaymentQuantityError'
  | 'MinOrderQuantityError'
  | 'MinPayoutAmountPerOrderError'
  | 'MinWithdrawalQuantityError'
  | 'NicknameAlreadyTakenError'
  | 'NicknameBlacklistedError'
  | 'NicknameDeclinedError'
  | 'NotSupportedBech32AddressError'
  | 'OrderAbortedError'
  | 'OrderNotFoundError'
  | 'OrderRejectedError'
  | 'PhoneVerificationCodeHasExpiredError'
  | 'PhoneVerificationNotAvailableError'
  | 'PhoneVerificationPhoneNotReachableError'
  | 'ResetPasswordRequestExpiredError'
  | 'ResetPasswordRequestNotFoundError'
  | 'ResetPasswordTokenAlreadyUsedError'
  | 'SecondFactorRequiredError'
  | 'SessionTerminatedError'
  | 'SimplexAmountTooHighError'
  | 'SimplexAmountTooLowError'
  | 'SimplexInternalError'
  | 'TFAAlreadyDisabledError'
  | 'TFAAlreadyEnabledError'
  | 'TooManyPhoneVerificationAttemptsError'
  | 'TradingDisabledError'
  | 'TrollboxRateLimitError'
  | 'TrollboxUserPermanentlyBannedError'
  | 'TrollboxUserTempBannedError'
  | 'UnableToProvideAddressError'
  | 'UnauthorizedLoginError'
  | 'UserSuspendedError'
  | 'UserWithEmailAlreadyExistsError'
  | 'VerificationCodeExpiredError'
  | 'VerificationLinkExpiredError'
  | 'WithdrawalAlreadyConfirmedError'
  | 'WithdrawalNotFoundError'
  | 'WithdrawalsDisabledError';

export type IBotPermission = 'read' | 'place_order' | 'cancel_order' | 'withdraw';

export type ICardPaymentCryptoInstrument = 'BTC' | 'ETH';

export type ICardPaymentFiatInstrument =
  | 'USD'
  | 'EUR'
  | 'GBP'
  | 'RUB'
  | 'AUD'
  | 'JPY'
  | 'CAD'
  | 'CHF'
  | 'SEK'
  | 'DKK'
  | 'CZK'
  | 'HUF'
  | 'NOK'
  | 'PLN'
  | 'NZD'
  | 'TRY'
  | 'ZAR'
  | 'ILS';

export type ICardPaymentSortField =
  | 'id'
  | 'payment_id'
  | 'user_id'
  | 'quote_id'
  | 'order_id'
  | 'target_address'
  | 'instrument'
  | 'quantity'
  | 'fiat_instrument'
  | 'fiat_quantity'
  | 'created_at'
  | 'updated_at'
  | 'user_request_flow_completed_at'
  | 'submitted_at'
  | 'completed_at'
  | 'declined_at';

export type ICountryCode =
  | 'AF'
  | 'AX'
  | 'AL'
  | 'DZ'
  | 'AS'
  | 'AD'
  | 'AO'
  | 'AI'
  | 'AQ'
  | 'AG'
  | 'AR'
  | 'AM'
  | 'AW'
  | 'AU'
  | 'AT'
  | 'AZ'
  | 'BS'
  | 'BH'
  | 'BD'
  | 'BB'
  | 'BY'
  | 'BE'
  | 'BZ'
  | 'BJ'
  | 'BM'
  | 'BT'
  | 'BO'
  | 'BQ'
  | 'BA'
  | 'BW'
  | 'BV'
  | 'BR'
  | 'IO'
  | 'BN'
  | 'BG'
  | 'BF'
  | 'BI'
  | 'CV'
  | 'KH'
  | 'CM'
  | 'CA'
  | 'KY'
  | 'CF'
  | 'TD'
  | 'CL'
  | 'CN'
  | 'CX'
  | 'CC'
  | 'CO'
  | 'KM'
  | 'CG'
  | 'CD'
  | 'CK'
  | 'CR'
  | 'CI'
  | 'HR'
  | 'CU'
  | 'CW'
  | 'CY'
  | 'CZ'
  | 'DK'
  | 'DJ'
  | 'DM'
  | 'DO'
  | 'EC'
  | 'EG'
  | 'SV'
  | 'GQ'
  | 'ER'
  | 'EE'
  | 'SZ'
  | 'ET'
  | 'FK'
  | 'FO'
  | 'FJ'
  | 'FI'
  | 'FR'
  | 'GF'
  | 'PF'
  | 'TF'
  | 'GA'
  | 'GM'
  | 'GE'
  | 'DE'
  | 'GH'
  | 'GI'
  | 'GR'
  | 'GL'
  | 'GD'
  | 'GP'
  | 'GU'
  | 'GT'
  | 'GG'
  | 'GN'
  | 'GW'
  | 'GY'
  | 'HT'
  | 'HM'
  | 'VA'
  | 'HN'
  | 'HK'
  | 'HU'
  | 'IS'
  | 'IN'
  | 'ID'
  | 'IR'
  | 'IQ'
  | 'IE'
  | 'IM'
  | 'IL'
  | 'IT'
  | 'JM'
  | 'JP'
  | 'JE'
  | 'JO'
  | 'KZ'
  | 'KE'
  | 'KI'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KG'
  | 'LA'
  | 'LV'
  | 'LB'
  | 'LS'
  | 'LR'
  | 'LY'
  | 'LI'
  | 'LT'
  | 'LU'
  | 'MO'
  | 'MK'
  | 'MG'
  | 'MW'
  | 'MY'
  | 'MV'
  | 'ML'
  | 'MT'
  | 'MH'
  | 'MQ'
  | 'MR'
  | 'MU'
  | 'YT'
  | 'MX'
  | 'FM'
  | 'MD'
  | 'MC'
  | 'MN'
  | 'ME'
  | 'MS'
  | 'MA'
  | 'MZ'
  | 'MM'
  | 'NA'
  | 'NR'
  | 'NP'
  | 'NL'
  | 'NC'
  | 'NZ'
  | 'NI'
  | 'NE'
  | 'NG'
  | 'NU'
  | 'NF'
  | 'MP'
  | 'NO'
  | 'OM'
  | 'PK'
  | 'PW'
  | 'PS'
  | 'PA'
  | 'PG'
  | 'PY'
  | 'PE'
  | 'PH'
  | 'PN'
  | 'PL'
  | 'PT'
  | 'PR'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RU'
  | 'RW'
  | 'BL'
  | 'SH'
  | 'KN'
  | 'LC'
  | 'MF'
  | 'PM'
  | 'VC'
  | 'WS'
  | 'SM'
  | 'ST'
  | 'SA'
  | 'SN'
  | 'RS'
  | 'SC'
  | 'SL'
  | 'SG'
  | 'SX'
  | 'SK'
  | 'SI'
  | 'SB'
  | 'SO'
  | 'ZA'
  | 'GS'
  | 'SS'
  | 'ES'
  | 'LK'
  | 'SD'
  | 'SR'
  | 'SJ'
  | 'SE'
  | 'CH'
  | 'SY'
  | 'TW'
  | 'TJ'
  | 'TZ'
  | 'TH'
  | 'TL'
  | 'TG'
  | 'TK'
  | 'TO'
  | 'TT'
  | 'TN'
  | 'TR'
  | 'TM'
  | 'TC'
  | 'TV'
  | 'UG'
  | 'UA'
  | 'AE'
  | 'GB'
  | 'US'
  | 'UM'
  | 'UY'
  | 'UZ'
  | 'VU'
  | 'VE'
  | 'VN'
  | 'VG'
  | 'VI'
  | 'WF'
  | 'EH'
  | 'YE'
  | 'ZM'
  | 'ZW';

export type ICurrency =
  | 'RSD'
  | 'USD'
  | 'EUR'
  | 'CHF'
  | 'GBP'
  | 'RUB'
  | 'DKK'
  | 'NOK'
  | 'CZK'
  | 'PLN'
  | 'SEK'
  | 'AUD'
  | 'HUF'
  | 'CAD'
  | 'JPY'
  | 'NZD'
  | 'TRY'
  | 'ZAR'
  | 'ILS';

export type IDepositFailureCode = 'aborted' | 'lost' | 'inconsistent' | 'other';

export type IDepositSortField =
  | 'id'
  | 'transaction_id'
  | 'transaction_sub_id'
  | 'user_id'
  | 'target_address'
  | 'instrument'
  | 'quantity'
  | 'confirmations_received'
  | 'confirmations_required'
  | 'fee_abs'
  | 'fee_pct'
  | 'fee_total'
  | 'transfer_id'
  | 'created_at'
  | 'revealed_at'
  | 'finalized_at'
  | 'failure_code'
  | 'metadata'
  | 'consolidation_id'
  | 'completed_at';

export type IFiatInstrument =
  | 'RSD'
  | 'USD'
  | 'EUR'
  | 'CHF'
  | 'GBP'
  | 'RUB'
  | 'DKK'
  | 'NOK'
  | 'CZK'
  | 'PLN'
  | 'SEK'
  | 'AUD'
  | 'HUF'
  | 'CAD'
  | 'JPY'
  | 'NZD'
  | 'TRY'
  | 'ZAR'
  | 'ILS';

export type IGender = 'male' | 'female' | 'other';

export type IGeoLocationPinning = 'strict' | 'loose' | 'off';

export type IImageFormat = 'jpg' | 'png';

export type IInstrument =
  | 'BTC'
  | 'RSD'
  | 'ETH'
  | 'LTC'
  | 'SFX'
  | 'WSFX'
  | 'SFT'
  | 'LLM'
  | 'EUR'
  | 'USD'
  | 'CHF'
  | 'GBP'
  | 'RUB'
  | 'DKK'
  | 'NOK'
  | 'CZK'
  | 'PLN'
  | 'SEK'
  | 'AUD'
  | 'HUF'
  | 'CAD'
  | 'JPY'
  | 'NZD'
  | 'TRY'
  | 'ZAR'
  | 'ILS';

export type IKYCLevel2Method = 'image_upload' | 'in_person_verification' | 'letter_with_code';

export type IKYCTrack = 'base' | 'vqf';

export type ILanguage = 'en_us' | 'sr_cyrl' | 'sr_latn';

export type IMarketInstrument = 'BTC' | 'RSD' | 'ETH' | 'SFX' | 'WSFX' | 'SFT';

export type IOrderSide = 'buy' | 'sell';

export type IOrderType = 'limit' | 'market' | 'stop';

export type IPair =
  | 'BTC_RSD'
  | 'SFX_RSD'
  | 'SFT_RSD'
  | 'ETH_RSD'
  | 'LTC_RSD'
  | 'LLM_RSD'
  | 'SFX_BTC'
  | 'SFT_BTC'
  | 'ETH_BTC'
  | 'LTC_BTC'
  | 'LLM_BTC'
  | 'SFT_SFX'
  | 'ETH_SFX'
  | 'WSFX_SFX';

export type IPhoneVerificationPurpose = 'onboarding' | 'vqf_signature';

export type IPriceHistorianGroupingInterval =
  | 'minute'
  | 'minutes_5'
  | 'minutes_15'
  | 'minutes_30'
  | 'hour'
  | 'hours_2'
  | 'hours_4'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year';

export type IPublicTradeInfoSortField =
  | 'signature'
  | 'pair'
  | 'side'
  | 'price'
  | 'quantity'
  | 'timestamp'
  | 'user_id'
  | 'user_side'
  | 'order_id'
  | 'order_type'
  | 'fee_percent'
  | 'fee_quantity'
  | 'total'
  | 'sort_index';

export type IRole = 'customer' | 'admin' | 'superadmin';

export type ISecurityEventInfoSortField =
  | 'email'
  | 'id'
  | 'event_type'
  | 'user_id'
  | 'security_token'
  | 'additional_data'
  | 'timestamp'
  | 'ip_address'
  | 'has_geo_data'
  | 'country_iso_code'
  | 'country_name'
  | 'city_name'
  | 'latitude'
  | 'longitude'
  | 'accuracy_radius'
  | 'time_zone'
  | 'user_agent'
  | 'browser_family'
  | 'browser_major'
  | 'browser_minor'
  | 'browser_type'
  | 'os_family'
  | 'os_major'
  | 'os_minor'
  | 'device_family'
  | 'device_brand'
  | 'device_model'
  | 'device_type';

export type ISecurityEventType =
  | 'login_user'
  | 'register_customer'
  | 'delete_session'
  | 'force_log_out_user'
  | 'request_password_reset'
  | 'execute_password_reset'
  | 'request_email_verification'
  | 'execute_email_verification'
  | 'failed_login_limit_reached'
  | 'tfa_login_timeout'
  | 'tfa_enabled'
  | 'tfa_disabled'
  | 'email_changed'
  | 'password_changed'
  | 'user_profile_updated'
  | 'bot_created'
  | 'bot_updated'
  | 'bot_signature_secret_revealed';

export type ISessionTerminationReason =
  | 'renewed'
  | 'expired'
  | 'idle_timeout'
  | 'location_check'
  | 'ua_check'
  | 'renewal_conflict'
  | 'logout'
  | 'deleted_by_owner'
  | 'deleted_by_admin'
  | 'password_change'
  | 'user_suspended'
  | 'unknown';

export type ISOFOrigin = 'salary' | 'inheritance' | 'business' | 'other';

export type ISOFSalaryRange = 'sr_0_20' | 'sr_20_60' | 'sr_60_100' | 'sr_100_500' | 'sr_500_plus';

export type ISortDirection = 'asc' | 'desc';

export type ITrollboxRoom = 'en' | 'sr';

export type IUABrowserType =
  | 'app'
  | 'bot'
  | 'feedreader'
  | 'headless'
  | 'mail'
  | 'mediaplayer'
  | 'podcast'
  | 'proxy';

export type IUADeviceType =
  | 'camera'
  | 'car'
  | 'console'
  | 'glasses'
  | 'phone'
  | 'player'
  | 'smartphone'
  | 'smartspeaker'
  | 'tablet'
  | 'tv'
  | 'watch';

export type IUSStateCode =
  | 'AL'
  | 'AK'
  | 'AZ'
  | 'AR'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DE'
  | 'DC'
  | 'FL'
  | 'GA'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MD'
  | 'MA'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY';

export type IWebSocketEvent =
  | 'broadcast_message'
  | 'market_depth'
  | 'price_summary_24h'
  | 'public_trade'
  | 'user_trade'
  | 'order_added'
  | 'order_cancelled'
  | 'order_filled'
  | 'order_partially_filled'
  | 'order_triggered'
  | 'wallet'
  | 'delete_session'
  | 'deposit_incoming'
  | 'deposit_completed'
  | 'withdrawal_completed'
  | 'withdrawal_confirmed'
  | 'kyc_request_stalled'
  | 'kyc_request_resolved'
  | 'card_payment_approved'
  | 'card_payment_declined'
  | 'user_suspended'
  | 'nickname_resolved'
  | 'external_rates_updated'
  | 'market_rates_updated'
  | 'managed_buy_order_updated'
  | 'managed_sell_order_updated'
  | 'execute_email_verification';

export type IWebSocketSector = 'trollbox' | 'pair';

export type IWithdrawalSortField =
  | 'id'
  | 'transaction_id'
  | 'user_id'
  | 'target_address'
  | 'instrument'
  | 'quantity'
  | 'fee_abs'
  | 'fee_pct'
  | 'fee_total'
  | 'geo_location_id'
  | 'user_agent'
  | 'created_at'
  | 'confirmed_at'
  | 'confirmation_token'
  | 'approved_at'
  | 'taken_at'
  | 'completed_at'
  | 'more_info'
  | 'stuck_reason'
  | 'metadata'
  | 'failure_code'
  | 'cancelled_at'
  | 'transfer_id';

// *********************************************************************************************************************

export interface IApiActiveOrdersCriteria {
  /**
   * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
   */
  sort_field: string | string[];

  /**
   * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
   */
  sort_direction: ISortDirection | ISortDirection[];

  /**
   * Page to take. This is enabled in conjunction with page_size
   */
  page: number;

  /**
   * If this is set, the results will be limited to given page size
   */
  page_size: number;

  /**
   * Filter by the instrument pair
   */
  pair: string;
}

export interface IApiAnonymousMarketDepth {
  buy: string[][];

  sell: string[][];
}

export interface IApiAnonymousTradeInfo {
  /**
   * Signature uniquely identifies each trade and is derived from the involved orders.
   */
  signature: string;

  /**
   * One of INSTRUMENT_PAIR-s
   */
  pair: IPair;

  /**
   * One of ORDER_SIDES, based on the matcher's SIDE
   */
  side: any;

  /**
   * Price at which the trade was made. To get the BASE instrument quantity, multiply price with quantity.
   */
  price: number;

  /**
   * Quantity of the traded QUOTE instrument
   */
  quantity: string;

  /**
   * Time when the trade was made. Taken from the matcher's timestamp
   */
  timestamp: string;
}

export interface IApiAppData {
  principal: IApiPrincipal;

  customer: IApiPublicCustomerInfo;

  system_time: IApiSystemTime;

  registration_policy: IApiRegistrationPolicy;

  payment_instrument_config: IApiPaymentInstrumentConfig;

  rates: IApiRates;

  price_summary_24h: IApiPriceHistorySummary;

  kyc1_policy: IApiKYC1Policy;
}

export interface IApiBot {
  /**
   * Identifier that uniquely identifies this bot
   */
  api_key: string;

  /**
   * User who owns the bot
   */
  user_id: any;

  /**
   * List of permissions this bot is given.
   */
  bot_permissions: IBotPermission[];

  /**
   * Optional IP address mask that can be used to limit origin of bot requests.
   * Eg. 192.168.0.0/16
   */
  cidr: string;

  /**
   * Last nonce that was used by this bot. This must always be increasing.
   */
  last_nonce: number;

  /**
   * When was bot record first created
   */
  created_at: string;

  /**
   * If bot is disabled, this will be the timestamp when. User can disable own bots.
   */
  disabled_at: string;

  /**
   * If bot is suspended, this will be the timestamp when. Only admins can suspend bots.
   */
  suspended_at: string;

  /**
   * When was bot last updated by user (excludes seen_at)
   */
  updated_at: any;

  /**
   * When was bot last seen at
   */
  seen_at: string;

  /**
   * If bot is deleted, this will be set
   */
  deleted_at: string;

  /**
   * Text describing what the bot does
   */
  description: string;
}

export interface IApiBotPayload {
  /**
   * List of permissions this bot is given.
   */
  bot_permissions: IBotPermission[];

  /**
   * Optional IP address mask that can be used to limit origin of bot requests.
   * Eg. 192.168.0.0/16
   */
  cidr: string;

  /**
   * Whether the bot should be disabled
   */
  disabled: boolean;

  /**
   * Text describing what the bot does
   */
  description: string;
}

export interface IApiCancelOrderReport {
  /**
   * ID of the cancellation request
   */
  cancellation_id: any;

  /**
   * Order that was cancelled
   */
  cancelled_order: IApiCensoredOrder;
}

export interface IApiCardPaymentsCriteria {
  /**
   * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
   */
  sort_field: ICardPaymentSortField | ICardPaymentSortField[];

  /**
   * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
   */
  sort_direction: ISortDirection | ISortDirection[];

  /**
   * General search filter. Business logic dictates where will this be applied
   */
  filter: any;

  /**
   * Page to take. This is enabled in conjunction with page_size
   */
  page: number;

  /**
   * If this is set, the results will be limited to given page size
   */
  page_size: number;

  /**
   * Set to true to include soft-deleted records
   */
  include_deleted: boolean;

  /**
   * Filter for user id
   */
  user_id: any;

  /**
   * Filter for instruments
   */
  instruments: IInstrument[];

  /**
   * Filter for fiat instrument
   */
  fiat_instrument: any;

  /**
   * Filter for whether a deposit has been completed. Leave empty to include both completed and declined.
   */
  completed: boolean;
}

export interface IApiCensoredCardPayment {
  /**
   * Corresponds with Simplex payment id
   */
  payment_id: any;

  /**
   * ID of the simplex order
   */
  order_id: any;

  /**
   * Address to Simplex will make the payment. This will be customer's deposit address.
   */
  target_address: any;

  /**
   * Instrument which to receive. Eg. 'BTC'
   */
  instrument: any;

  /**
   * Quantity to receive
   */
  quantity: string;

  /**
   * Fiat instrument to send. Eg. 'USD'
   */
  fiat_instrument: any;

  /**
   * Fiat quantity to send
   */
  fiat_quantity: string;

  created_at: string;

  /**
   * Timestamp when user hits "success" callback URL
   * Differentiates submitted from incomplete requests, hence we can show payment record to user
   */
  user_request_flow_completed_at: string;

  submitted_at: string;

  completed_at: string;

  declined_at: string;
}

export interface IApiCensoredDeposit {
  id: any;

  /**
   * ID of the source transaction (on the blockchain, for example)
   */
  transaction_id: any;

  /**
   * In cases where one transaction can make several payments, bankers can use this value to make a distinction
   * between multiple payments (transaction_id and transaction_sub_id must be unique within the system).
   * For example, in BTC, this could be index of the output within transaction.
   * Here's a transaction with multiple outputs going to the same address:
   * https://www.blockchain.com/btc/tx/4199f709bfab79f54938e80e11dc75c21a95e3183f8e8994306f5ff272fde42a?show_adv=true
   */
  transaction_sub_id: any;

  /**
   * Address to which the deposit is coming. This is how we determine the user to credit the deposit with.
   */
  target_address: any;

  /**
   * The deposit instrument.
   */
  instrument: any;

  /**
   * Quantity, given as a decimal number
   */
  quantity: string;

  /**
   * How many confirmations were received for a blockchain transaction.
   */
  confirmations_received: any;

  /**
   * How many blockchain confirmations are required for transaction to succeed.
   */
  confirmations_required: any;

  /**
   * Absolute fee that was applied (based on user's instrument config)
   */
  fee_abs: string;

  /**
   * Percent fee that was applied (based on user's instrument config).
   * If both percent and abs is present, percent is applied after abs.
   */
  fee_pct: number;

  /**
   * Total fee that was taken off from the quantity. fee_abs + (quantity - fee_abs) * (fee_pct / 100)
   */
  fee_total: string;

  /**
   * Date when deposit request was created.
   */
  created_at: string;

  /**
   * This is set when deposit is finalized. This is set by the banker in charge of managing the given instrument.
   * If failure_code is not set and finalized_at is, the deposit was successful and user's wallet can be credited.
   */
  finalized_at: string;

  /**
   * In case deposit has failed for whatever reason, this should be set to an explanation message.
   */
  failure_code: IDepositFailureCode;

  /**
   * Date when payment of a deposit is finally completed. In most cases, this will be the same date as
   * finalized_at. But if deposits are disabled or there are other issues, completion might be delayed.
   * User's wallet is only credited once deposit is completed. If deposit has been finalized
   * but not completed, user interface will display this state as "ON HOLD".
   */
  completed_at: string;
}

export interface IApiCensoredDepositAddress {
  /**
   * Address value
   */
  address: string;

  /**
   * User ID
   */
  user_id: string;

  /**
   * Instrument for which the address is
   */
  instruments: IMarketInstrument[];
}

export interface IApiCensoredKYCRequestResolvedEventData {
  /**
   * Level that was resolved. Null if no active request was found.
   */
  level: any;

  /**
   * Whether the request was granted or not
   */
  granted: any;

  customer: IApiPublicCustomerInfo;
}

export interface IApiCensoredManagedBuyOrder {
  /**
   * Human-readable order ID
   */
  id: string;

  /**
   * Customer is going to pay for order in this currency. EUR, USD or CHF
   */
  currency: ICurrency;

  /**
   * Instrument quantity to be payed
   */
  quantity: string;

  /**
   * Percent fee that was applied
   */
  fee_pct: number;

  /**
   * Fixed fee at the moment
   */
  fee_fixed: string;

  /**
   * Total fee that was taken off from the quantity
   */
  fee_total: string;

  /**
   * Percentage distribution among crypto instruments for buying
   */
  distribution: IApiManagedBuyOrderDistribution;

  estimated_gains: any;

  /**
   * Reference number serving as a unique payment identifier
   */
  payment_reference_number: string;

  /**
   * If this is set, it means the order has failed
   */
  failure_reason: string;

  /**
   * If this is set, it means the order has been suspended
   */
  suspension_reason: string;

  /**
   * Date when the order was put on hold by the service operator
   */
  suspended_at: string;

  /**
   * Date when the order request was taken by the service operator
   */
  taken_at: string;

  /**
   * Date when the order request was submitted by the user
   */
  created_at: string;

  /**
   * Date when the payment has been received
   */
  payment_received_at: string;

  /**
   * Date when the order was updated
   */
  updated_at: string;

  /**
   * This is set to the date when the order has been completed
   */
  completed_at: string;

  /**
   * Set if the order has been cancelled by the user
   */
  cancelled_at: string;
}

export interface IApiCensoredManagedBuyOrdersCriteria {
  /**
   * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
   */
  sort_field: string | string[];

  /**
   * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
   */
  sort_direction: ISortDirection | ISortDirection[];

  /**
   * General search filter. Business logic dictates where will this be applied
   */
  filter: any;

  /**
   * Page to take. This is enabled in conjunction with page_size
   */
  page: number;

  /**
   * If this is set, the results will be limited to given page size
   */
  page_size: number;

  /**
   * Set to true to include soft-deleted records
   */
  include_deleted: boolean;

  /**
   * Filter for payment currency
   */
  currency: any;

  /**
   * Filter only completed orders
   */
  completed: boolean;
}

export interface IApiCensoredManagedSellOrder {
  /**
   * Human-readable order ID
   */
  id: string;

  /**
   * The amount of instrument user want's to withdraw
   */
  quantity: string;

  /**
   * Crypto instrument user wants to withdraw
   */
  instrument: IMarketInstrument;

  /**
   * Currency customer wishes to receive (USD, EUR or CHF)
   */
  payout_currency: IInstrument;

  bank_name: string;

  /**
   * The account number where we will send money.
   */
  bank_account_number: string;

  swift: string;

  recipient_name: string;

  recipient_address: string;

  fee_pct: number;

  fee_fixed: string;

  /**
   * Total fee that was taken off from the quantity
   */
  fee_total: string;

  /**
   * If this is set, it means the order has failed
   */
  failure_reason: string;

  /**
   * Date when the order request was taken by the service operator
   */
  taken_at: string;

  /**
   * If this is set, it means the order has been suspended
   */
  suspension_reason: string;

  /**
   * Date when the order was put on hold by the service operator
   */
  suspended_at: string;

  /**
   * Date when the order request was submitted by the user
   */
  created_at: string;

  /**
   * Date when the order was updated
   */
  updated_at: string;

  /**
   * This is set to the date when the order has been completed
   */
  completed_at: string;

  /**
   * Set if the order has been cancelled by the user
   */
  cancelled_at: string;

  /**
   * Set if the order has been refunded
   */
  refunded_at: string;
}

export interface IApiCensoredOrder {
  /**
   * Order ID. Auto-generated string.
   */
  id: string;

  /**
   * User ID
   */
  user_id: string;

  /**
   * Instrument pair for this order
   */
  pair: IPair;

  /**
   * Order type. One of ORDER_TYPES.
   */
  type: IOrderType;

  /**
   * Side of the order book. One of ORDER_SIDES, buy or sell.
   */
  side: IOrderSide;

  /**
   * Price, representing the amount of BASE instrument for 1 of QUOTE instrument.
   * Used to position limit and stop orders.
   */
  price: number;

  /**
   * The amount of QUOTE instrument user wants to buy or sell.
   */
  quantity: string;

  /**
   * Fee that will be applied for this order, whether owner is buyer and seller.
   * It will be subtracted from the amount user will have to give or that they will receive
   * (whichever is the base instrument for this order)
   */
  fee: number;

  /**
   * If this is cancellation request, this is the id to cancel. A lot of other parameters don't matter for these.
   */
  cancel_order_id: any;

  /**
   * ID of the original (stop) order that has triggered the creation of this order
   */
  triggered_by_id: any;

  /**
   * Date when this order was processed by the system
   */
  timestamp: string;
}

export interface IApiCensoredOrderFilledEventData {
  order: IApiCensoredOrder;

  /**
   * Whether filled order is a matcher or matchee. If matcher, it will be removed from order book.
   */
  is_matcher: boolean;
}

export interface IApiCensoredOrderPartiallyFilledEventData {
  order: IApiCensoredOrder;

  remaining_quantity: string;

  /**
   * Whether partially filled order is a matcher or matchee
   */
  is_matcher: boolean;
}

export interface IApiCensoredSessionInfo {
  /**
   * Access token that uniquely identifies session
   */
  access_token: any;

  /**
   * When was this session created
   */
  created_at: string;

  /**
   * Date when this token will expire.
   */
  expires_at: string;

  /**
   * Number of milliseconds session can be "idle" (without HTTP requests), before user is force-logged out.
   * Client should observe this value and send keep alive requests if user has requested so.
   */
  idle_timeout: number;

  /**
   * The last time the user with this session was seen at their client (eg. moving a mouse or performing some activity).
   * This will have ramifications regarding idle_timeout.
   */
  seen_at: string;

  /**
   * If session is terminated, this will be set to date when
   */
  terminated_at: string;

  /**
   * One of SESSION_TERMINATION_REASONS
   */
  termination_reason: ISessionTerminationReason;

  ip_address: any;

  /**
   * True if IP address was found in the GeoIP database
   */
  has_geo_data: boolean;

  country_iso_code: any;

  country_name: any;

  city_name: any;

  latitude: any;

  longitude: any;

  accuracy_radius: any;

  time_zone: any;

  /**
   * Actual user agent string that was used to derive this info
   */
  user_agent: any;

  browser_family: any;

  browser_major: any;

  browser_minor: any;

  browser_type: IUABrowserType;

  os_family: any;

  os_major: any;

  os_minor: any;

  device_family: any;

  device_brand: any;

  device_model: any;

  device_type: IUADeviceType;
}

export interface IApiCensoredWithdrawal {
  id: any;

  /**
   * ID of the transaction that was made (on the blockchain, for example)
   */
  transaction_id: any;

  /**
   * Address where the instrument was sent
   */
  target_address: any;

  /**
   * The withdrawal instrument.
   */
  instrument: IMarketInstrument;

  /**
   * The withdrawal quantity, as a decimal
   */
  quantity: string;

  /**
   * Absolute fee that was applied (based on user's instrument config)
   */
  fee_abs: string;

  /**
   * Percent fee that was applied (based on user's instrument config)
   */
  fee_pct: number;

  /**
   * Total fee that was taken off from the quantity. fee_abs + (quantity - fee_abs) * (fee_pct / 100)
   * This can be used as basis for calculating revenue.
   */
  fee_total: string;

  /**
   * Date when the withdrawal request was submitted by user
   */
  created_at: string;

  /**
   * This is set to the date when withdrawal has been confirmed. This happens when user
   * clicks "confirm" link in their email, or automatically if that option is disabled.
   */
  confirmed_at: string;

  /**
   * Date when the withdrawal request was taken by the withdrawal processor
   */
  taken_at: string;

  /**
   * Date when the withdrawal was completed, one way or the other
   */
  completed_at: string;

  /**
   * If this is set, it means the transaction is paused until customer provides more info
   */
  more_info: string;

  /**
   * Failure code. If this is set, it means the Withdrawal has failed.
   */
  failure_code: any;

  /**
   * Set if withdrawal has been cancelled by the user
   */
  cancelled_at: string;
}

export interface IApiChangeEmailPayload {
  new_email: string;

  password?: string;

  tfa_token?: string;
}

export interface IApiChangePasswordPayload {
  /**
   * New password to set. Must follow all password rules as when registering
   */
  new_password: string;

  /**
   * Existing password must match the password we already have on record
   */
  existing_password: string;

  /**
   * Current TFA token, if TFA is enabled
   */
  tfa_token?: string;
}

export interface IApiCSVDownload {
  /**
   * ID the client can call to download the generated CSV data
   */
  id: any;

  /**
   * Name of the generated file.
   */
  file_name: any;

  page: any;

  page_size: any;

  total_records: any;

  total_pages: any;
}

export interface IApiCustomerSecurityEventInfosCriteria {
  /**
   * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
   */
  sort_field: ISecurityEventInfoSortField | ISecurityEventInfoSortField[];

  /**
   * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
   */
  sort_direction: ISortDirection | ISortDirection[];

  /**
   * General search filter. Business logic dictates where will this be applied
   */
  filter: any;

  /**
   * Page to take. This is enabled in conjunction with page_size
   */
  page: number;

  /**
   * If this is set, the results will be limited to given page size
   */
  page_size: number;

  /**
   * Set to true to include soft-deleted records
   */
  include_deleted: boolean;

  /**
   * Comma-separated list of event types to filter down to
   */
  event_types: ISecurityEventType[];
}

export interface IApiCustomerSourceOfFundsPayload {
  /**
   * Source of funds: Customer's profession
   */
  sof_profession: any;

  /**
   * Source of funds: One of SOF_SALARY_RANGES
   */
  sof_salary_range: ISOFSalaryRange;

  /**
   * Source of funds: Origin of assets deposited. One of SOF_ORIGINS
   */
  sof_origin: ISOFOrigin;

  /**
   * Source of funds: In case sof_origin is "other", this is custom input
   */
  sof_origin_other: any;

  /**
   * Source of funds: Expected volume and frequency of payments
   */
  sof_expected_volume: any;
}

export interface IApiDepositsForCustomerCriteria {
  /**
   * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
   */
  sort_field: IDepositSortField | IDepositSortField[];

  /**
   * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
   */
  sort_direction: ISortDirection | ISortDirection[];

  /**
   * General search filter. Business logic dictates where will this be applied
   */
  filter: any;

  /**
   * Page to take. This is enabled in conjunction with page_size
   */
  page: number;

  /**
   * If this is set, the results will be limited to given page size
   */
  page_size: number;

  /**
   * Set to true to include soft-deleted records
   */
  include_deleted: boolean;

  /**
   * Filter for instruments
   */
  instruments: IInstrument[];

  /**
   * Filter for whether a deposit has been finalized. Leave empty to include both finalized and incoming.
   */
  finalized: boolean;

  /**
   * Filter for whether a deposit has been completed. A deposit can be finalized, but not completed.
   * Leave empty to include both completed and not completed.
   */
  completed: boolean;
}

export interface IApiEmailVerificationCodePayload {
  /**
   * Submitted code
   */
  code: any;
}

export interface IApiEmailVerificationResult {
  user_id: string;

  email_verified_at: string;
}

export interface IApiEstimatorData {
  BTC: IApiEstimatorInstrumentData;

  ETH: IApiEstimatorInstrumentData;

  SFT: IApiEstimatorInstrumentData;

  SFX: IApiEstimatorInstrumentData;
}

export interface IApiEstimatorInstrumentData {
  market_depth: IApiAnonymousMarketDepth;

  max_price: number;

  partner_provided: boolean;
}

export interface IApiExecuteResetPasswordPayload {
  /**
   * User's new password
   */
  new_password: string;

  /**
   * Current TFA token, if TFA is enabled
   */
  token: string;
}

export interface IApiExternalRates {
  BTC: { [key: string]: number };

  RSD: { [key: string]: number };

  USD: { [key: string]: number };

  EUR: { [key: string]: number };

  CHF: { [key: string]: number };

  GBP: { [key: string]: number };

  RUB: { [key: string]: number };

  DKK: { [key: string]: number };

  NOK: { [key: string]: number };

  CZK: { [key: string]: number };

  PLN: { [key: string]: number };

  SEK: { [key: string]: number };

  AUD: { [key: string]: number };

  HUF: { [key: string]: number };

  CAD: { [key: string]: number };

  JPY: { [key: string]: number };

  NZD: { [key: string]: number };

  TRY: { [key: string]: number };

  ZAR: { [key: string]: number };

  ILS: { [key: string]: number };
}

export interface IApiGlobalExchangeData {
  global_pair_policy: { [key: string]: IApiPairPolicy };

  market_trades: IApiPublicTradeInfo[];

  market_depth: IApiMarketDepth;
}

export interface IApiGraphData {
  BTC: IApiGraphDataForInstrument[];

  ETH: IApiGraphDataForInstrument[];

  SFX: IApiGraphDataForInstrument[];

  SFT: IApiGraphDataForInstrument[];

  timestamp: string;
}

export interface IApiGraphDataForInstrument {
  interval: string;

  instrument: string;

  currency: string;

  points: number[];

  high: number;

  low: number;
}

export interface IApiImage {
  /**
   * Auto-generated image id, uniquely identifying this image
   */
  id: string;

  /**
   * Subdirectory inside the store where image is kept
   */
  directory: string;

  /**
   * Original file name of image, if available
   */
  original_name: string;

  /**
   * Master image format, based on the original upload
   */
  format: IImageFormat;

  /**
   * Timestamp when image was created
   */
  created_at: string;

  /**
   * Timestamp when image files were deleted from storage
   */
  deleted_at: string;
}

export interface IApiImageUploadBody {
  image1: any;

  image2: any;

  image3: any;
}

export interface IApiIssueAddressPayload {
  instrument: IMarketInstrument;
}

export interface IApiKYC1Policy {
  /**
   * List of allowed US states
   */
  us_state_whitelist: IUSStateCode[];
}

export interface IApiLogInPayload {
  /**
   * Email address, uniquely identifies user in the system
   */
  email: string;

  /**
   * Password for the user.
   */
  password: string;

  /**
   * If user has enabled 2FA, they must provide a token from google authenticator here.
   */
  tfa_token?: string;

  /**
   * Token obtained by solving captcha challenge
   */
  captcha_token?: string;
}

export interface IApiManagedBuyOrder {
  /**
   * Human-readable order ID
   */
  id: string;

  /**
   * User who owns the order
   */
  user_id: any;

  user_email: any;

  /**
   * Customer is going to pay for order in this currency. EUR, USD or CHF
   */
  currency: ICurrency;

  /**
   * Instrument quantity to be payed
   */
  quantity: string;

  /**
   * Percent fee that was applied
   */
  fee_pct: number;

  /**
   * Fixed fee at the moment
   */
  fee_fixed: string;

  /**
   * Total fee that was taken off from the quantity
   */
  fee_total: string;

  /**
   * Percentage distribution among crypto instruments for buying
   */
  distribution: IApiManagedBuyOrderDistribution;

  estimated_gains: any;

  /**
   * Reference number serving as a unique payment identifier
   */
  payment_reference_number: string;

  /**
   * If this is set, it means the order has failed
   */
  failure_reason: string;

  /**
   * If this is set, it means the order has been suspended
   */
  suspension_reason: string;

  /**
   * Date when the order was put on hold by the service operator
   */
  suspended_at: string;

  /**
   * Date when the order request was taken by the service operator
   */
  taken_at: string;

  /**
   * Date when the order request was submitted by the user
   */
  created_at: string;

  /**
   * Date when the payment has been received
   */
  payment_received_at: string;

  /**
   * Date when the order was updated
   */
  updated_at: string;

  /**
   * This is set to the date when the order has been completed
   */
  completed_at: string;

  /**
   * Set if the order has been cancelled by the user
   */
  cancelled_at: string;
}

/**
 * Percentage distribution among crypto instruments for buying
 */
export interface IApiManagedBuyOrderDistribution {
  BTC: number;

  ETH: number;

  SFX: number;

  SFT: number;
}

export interface IApiManagedBuyOrderRequestPayload {
  /**
   * Customer is going to pay for order in this currency. EUR, USD or CHF
   */
  currency: ICurrency;

  /**
   * Instrument quantity to be payed
   */
  quantity: string;

  /**
   * Percentage distribution among crypto instruments for buying
   */
  distribution: IApiManagedBuyOrderDistribution;

  estimated_gains: any;
}

export interface IApiManagedSellOrder {
  /**
   * Human-readable order ID
   */
  id: string;

  /**
   * User who owns the order
   */
  user_id: any;

  user_email: any;

  /**
   * The amount of instrument user want's to withdraw
   */
  quantity: string;

  /**
   * Crypto instrument user wants to withdraw
   */
  instrument: IMarketInstrument;

  /**
   * Currency customer wishes to receive (USD, EUR or CHF)
   */
  payout_currency: IInstrument;

  bank_name: string;

  /**
   * The account number where we will send money.
   */
  bank_account_number: string;

  swift: string;

  recipient_name: string;

  recipient_address: string;

  fee_pct: number;

  fee_fixed: string;

  /**
   * Total fee that was taken off from the quantity
   */
  fee_total: string;

  /**
   * If this is set, it means the order has failed
   */
  failure_reason: string;

  /**
   * Date when the order request was taken by the service operator
   */
  taken_at: string;

  interchange_id: any;

  reverse_interchange_id: any;

  /**
   * If this is set, it means the order has been suspended
   */
  suspension_reason: string;

  /**
   * Date when the order was put on hold by the service operator
   */
  suspended_at: string;

  /**
   * Date when the order request was submitted by the user
   */
  created_at: string;

  /**
   * Date when the order was updated
   */
  updated_at: string;

  /**
   * This is set to the date when the order has been completed
   */
  completed_at: string;

  /**
   * Set if the order has been cancelled by the user
   */
  cancelled_at: string;

  /**
   * Set if the order has been refunded
   */
  refunded_at: string;
}

export interface IApiManagedSellOrderRequestPayload {
  /**
   * Crypto instrument user wants to withdraw
   */
  instrument: IInstrument;

  /**
   * The amount of instrument user want's to withdraw
   */
  quantity: string;

  /**
   * Whether the maximum allowed quantity should be withdrawn
   */
  auto_fill_quantity?: boolean;

  /**
   * Currency customer wishes to receive (USD, EUR or CHF)
   */
  payout_currency: ICurrency;

  bank_name: string;

  /**
   * The account number where we will send money.
   */
  bank_account_number: string;

  swift: string;

  recipient_name: string;

  recipient_address: string;
}

export interface IApiManagedSellOrdersCriteria {
  /**
   * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
   */
  sort_field: string | string[];

  /**
   * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
   */
  sort_direction: ISortDirection | ISortDirection[];

  /**
   * General search filter. Business logic dictates where will this be applied
   */
  filter: any;

  /**
   * Page to take. This is enabled in conjunction with page_size
   */
  page: number;

  /**
   * If this is set, the results will be limited to given page size
   */
  page_size: number;

  /**
   * Set to true to include soft-deleted records
   */
  include_deleted: boolean;

  /**
   * Filter for payout currency
   */
  payout_currency: any;

  /**
   * Filter only completed orders
   */
  completed: boolean;
}

export interface IApiManagedUserConfig {
  /**
   * Crypto instruments tradable for fiat.
   */
  supported_crypto: IMarketInstrument[];

  /**
   * Fiat instruments for which we supported buying/selling crypto.
   */
  supported_fiat: IFiatInstrument[];

  /**
   * Minimal payment amount we will allow per order.
   */
  buy_order_min_payment_quantity: string;

  buy_order_payment_instrument: IInstrument;

  /**
   * Buy order fee expressed in percentage
   */
  buy_order_fee_pct: number;

  /**
   * Fixed buy order fee expressed in fiat
   */
  buy_order_fee_fixed: string;

  /**
   * Minimal payout amount we will allow per order.
   */
  sell_order_min_payout_quantity: string;

  sell_order_payout_instrument: IInstrument;

  /**
   * Sell order fee expressed in percentage
   */
  sell_order_fee_pct: number;

  /**
   * Fixed sell order fee expressed in fiat
   */
  sell_order_fee_fixed: string;

  /**
   * If true, the service is hidden from UI entirely.
   */
  hide_service: boolean;

  /**
   * If true, the customer has completed their setup and is registered to use the Managed.
   * They should be shown the pages with Xcalibra Managed.
   */
  registered: any;

  /**
   * Crypto instruments disabled for user
   */
  disable_instruments: { [key: string]: string };
}

export interface IApiMarketDepth {
  /**
   * Pair for which this depth is
   */
  pair: any;

  /**
   * How many digits have we grouped
   */
  scale: any;

  /**
   * How many levels did we slice at
   */
  limit: any;

  buy: IApiMarketDepthSlice;

  sell: IApiMarketDepthSlice;
}

export interface IApiMarketDepthLevel {
  /**
   * Price at this level
   */
  price: number;

  /**
   * Quantity of orders at this level
   */
  quantity: string;

  /**
   * Total value of the level in base instrument, price * quantity
   */
  total: string;

  /**
   * Total number of orders at this level
   */
  count: number;

  /**
   * Sum of all the quantites at this level and below
   */
  quantity_depth: string;

  /**
   * Sum of all the totals at this level and below
   */
  total_depth: string;

  _total: any;
}

export interface IApiMarketDepthSlice {
  /**
   * How many digits have we grouped
   */
  scale: any;

  levels: IApiMarketDepthLevel[];

  /**
   * How many levels did we slice at
   */
  limit: any;

  /**
   * Total supply of quote instrument in the list (not just the slice). Corresponds to quantity depth of the last level.
   */
  quote_supply: string;

  /**
   * Total supply of base instrument in the list (not just the slice). Corresponds to total depth of the last level.
   */
  base_supply: string;
}

export interface IApiMarketRates {
  BTC: { [key: string]: number };

  ETH: { [key: string]: number };

  SFX: { [key: string]: number };

  SFT: { [key: string]: number };

  RSD: { [key: string]: number };
}

export interface IApiOrderBookContentForUser {
  pair: any;

  buy_list: IApiCensoredOrder[];

  sell_list: IApiCensoredOrder[];

  user_id: any;
}

export interface IApiOrderPayload {
  /**
   * Instrument pair for this order
   */
  pair: IPair;

  /**
   * Order type. One of ORDER_TYPES.
   */
  type: IOrderType;

  /**
   * Side of the order book. One of ORDER_SIDES, buy or sell.
   */
  side: IOrderSide;

  /**
   * Price, representing the amount of BASE instrument for 1 of QUOTE instrument.
   * Used to position limit and stop orders.
   */
  price: number;

  /**
   * The amount of QUOTE instrument user wants to buy or sell.
   */
  quantity: string;
}

export interface IApiPairPolicy {
  /**
   * Pair to which this config applies. If null, applies to all pairs.
   */
  pair: string;

  /**
   * User to which this config applies. If null, applies to all users.
   */
  user_id: any;

  /**
   * If true, given pair is disabled.
   */
  disable_orders: boolean;

  /**
   * Explanation why orders are disabled. Typed in by the admin.
   */
  disable_orders_reason: any;

  /**
   * Fee for to be taken out of trades. Presented as multiplier to be applied to either side of trade.
   */
  trade_fee: number;

  /**
   * Minimal quantity of order. Presented in BASE instrument. If buying, converted to base instrument using converter.
   */
  min_order: string;

  /**
   * Use disable_orders instead
   */
  disabled_orders: any;
}

export interface IApiPaymentConfigForUser {
  /**
   * If true, card payments are disabled for user.
   */
  disable_service: boolean;

  /**
   * Explanation why card payments are disabled.
   */
  disable_service_reason: string;

  /**
   * Crypto instruments disabled for user
   */
  disable_instruments: { [key: string]: string };

  payment_limit: IApiPaymentLimitPaymentConfigForUser;
}

export interface IApiPaymentInstrumentConfig {
  /**
   * Fiat instruments supported for credit card payments.
   */
  supported_fiat: string[];

  /**
   * Crypto instruments tradable for fiat.
   */
  supported_crypto: string[];
}

export interface IApiPaymentLimitPaymentConfigForUser {
  instrument: IInstrument;

  minimal: number;

  maximal: number;
}

export interface IApiPaymentRequestPayload {
  quote_id: string;
}

export interface IApiPaymentResult {
  quote_id: string;

  payment_id: string;

  order_id: string;

  user_id: string;

  instrument: string;

  quantity: any;

  target_address: string;

  fiat_instrument: string;

  fiat_quantity: any;

  payment_post_url: string;

  client_id: string;

  return_url_success: string;

  return_url_fail: string;
}

export interface IApiPhoneVerificationCodePayload {
  /**
   * Purpose for which is this verification.
   * One of PHONE_VERIFICATION_PURPOSES
   */
  purpose: IPhoneVerificationPurpose;

  /**
   * Phone number being verified
   */
  phone: string;

  /**
   * Code that must be inputted to verify. Can be null in case of an admin override.
   */
  code: any;
}

export interface IApiPhoneVerificationRequestPayload {
  /**
   * Purpose for which is this verification.
   * One of PHONE_VERIFICATION_PURPOSES
   */
  purpose: IPhoneVerificationPurpose;

  /**
   * Phone number being verified
   */
  phone: string;
}

export interface IApiPhoneVerificationState {
  /**
   * Purpose for which is this verification.
   * One of PHONE_VERIFICATION_PURPOSES
   */
  purpose: IPhoneVerificationPurpose;

  /**
   * Phone number being verified
   */
  phone: string;

  /**
   * User for whom this verification is
   */
  user_id: any;

  /**
   * When was this verification sent
   */
  created_at: string;

  /**
   * Whether this phone for this customer has been verified
   */
  phone_verified: boolean;

  /**
   * Whether verification has been terminated. If true, client should assume they can't use this verification and request another one
   */
  terminated: boolean;

  /**
   * How long must client wait until they send a request for the same user id and phone number combo
   */
  request_cooldown: number;
}

export interface IApiPlaceOrderReport {
  /**
   * Order that was submitted
   */
  order: IApiCensoredOrder;

  /**
   * Whether the order was added to order book
   */
  added: boolean;

  /**
   * Remaining quantity after matching (if applicable)
   */
  remaining_quantity: string;

  /**
   * List of trades generated by this order (if applicable)
   */
  trades: IApiPublicTradeInfo[];
}

export interface IApiPriceHistory {
  /**
   * ID of the database record
   */
  id: any;

  /**
   * Instrument pair for which this record is
   */
  pair: any;

  /**
   * Price at the open of the time period
   */
  open: number;

  /**
   * Highest price during the time period
   */
  high: number;

  /**
   * Lowest price during the time period
   */
  low: number;

  /**
   * Price at the close of the time period
   */
  close: number;

  /**
   * Number of trades during the period
   */
  count: number;

  /**
   * Trade volume during the period,
   * sum of the BASE quantity traded during this period
   */
  volume: string;

  /**
   * Sum of the QUOTE quantity traded during this period
   */
  quantity: string;

  /**
   * Date referring to the start of the history period
   */
  timestamp: string;
}

/**
 * Criteria for filtering OHLC data
 */
export interface IApiPriceHistoryCriteria {
  /**
   * Only history for the given pair
   */
  pair: IPair;

  /**
   * Return history grouped by the given interval. One of GROUPING_INTERVALS.
   */
  interval: IPriceHistorianGroupingInterval;

  /**
   * Return records that are at or after the given date
   */
  from_timestamp?: string;

  /**
   * Return records that are before given date
   */
  to_timestamp?: string;

  /**
   * Convert all values to given instrument
   */
  convert_to?: any;

  /**
   * Limit number of returned items
   */
  limit?: number;
}

export interface IApiPriceHistorySummary {
  /**
   * If set, prices will be converted to given instrument at current exchange rate
   */
  conversion_instrument: any;

  /**
   * From date
   */
  from_timestamp: string;

  /**
   * To date
   */
  to_timestamp: string;

  by_pair: { [key: string]: IApiPriceHistory };
}

export interface IApiPrincipal {
  session: IApiSession;

  user: IApiUser;
}

export interface IApiPublicCustomerInfo {
  /**
   * NOTE: Customer and user share the same id
   */
  user_id: any;

  /**
   * First name
   */
  first_name: any;

  /**
   * Middle name
   */
  middle_name: any;

  /**
   * Last name
   */
  last_name: any;

  /**
   * Date of birth
   */
  dob: string;

  /**
   * One of GENDERS-s
   */
  gender: IGender;

  /**
   * Nationality, 2 letter country code
   */
  nationality: ICountryCode;

  /**
   * Street address, eg. Maple Street 123
   */
  residence_address: any;

  /**
   * Name of the city
   */
  residence_city: any;

  /**
   * Postal code
   */
  residence_postal_code: any;

  /**
   * Country that corresponds with the address field
   */
  residence_country: ICountryCode;

  /**
   * US state
   */
  residence_us_state: IUSStateCode;

  /**
   * Mobile phone number
   */
  phone: string;

  /**
   * Whether phone has been verified or not
   */
  phone_verified: boolean;

  /**
   * Source of funds: Customer's profession
   */
  sof_profession: any;

  /**
   * Source of funds: One of SOF_SALARY_RANGES
   */
  sof_salary_range: ISOFSalaryRange;

  /**
   * Source of funds: Origin of assets deposited. One of SOF_ORIGINS
   */
  sof_origin: ISOFOrigin;

  /**
   * Source of funds: In case sof_origin is "other", this is custom input
   */
  sof_origin_other: any;

  /**
   * Source of funds: Expected volume and frequency of payments
   */
  sof_expected_volume: any;

  created_at: string;

  updated_at: string;

  /**
   * Reference number to be used in KYC micropayments. This will only be set once conditions are met.
   */
  reference_number_for_micropayments: any;

  kyc_locked: any;

  /**
   * Message to be shown to customer in case KYC is locked
   */
  kyc_lock_message: any;

  /**
   * Currently granted KYC level. Matches the data from User.
   */
  kyc_level_granted: any;

  /**
   * Currently granted VQF KYC level. Matches the data from User.
   */
  kyc_level_granted_vqf: any;

  /**
   * Which KYC type is currently being requested. One of KYC_TYPE
   */
  kyc_request_track: IKYCTrack;

  /**
   * If user has requested a KYC level, this will hold the number of currently active request.
   */
  kyc_level_requested: any;

  /**
   * Unified flag telling frontend that KYC request is being processed and that it should not
   * allow customer to submit anything until this is resolved.
   */
  kyc_processing_started_at: any;

  /**
   * If last KYC request has failed, this might contain a failure message. If message is not set,
   * UI is free not to display anything, and just let the customer resubmit.
   */
  kyc_failure_message: any;

  /**
   * If this is true, verification has been submitted to the provider and now we are waiting for resolution.
   * If this is true, UI should show a spinner.
   */
  kyc1_verification_waiting: boolean;

  /**
   * Method to use when validating KYC level 2
   */
  kyc2_method: IKYCLevel2Method;

  /**
   * If micropayment was received, we will include information about it here
   */
  kyc3_micropayment: IApiPublicCustomerMicropaymentInfo;
}

/**
 * If micropayment was received, we will include information about it here
 */
export interface IApiPublicCustomerMicropaymentInfo {
  /**
   * Transaction id provided by the bank
   */
  transaction_id: any;

  /**
   * Quantity received
   */
  quantity: string;

  /**
   * Fiat instrument received
   */
  instrument: any;

  /**
   * Whether this micropayment was approved as valid. Approving it can cause KYC level to be granted.
   */
  approved: boolean;

  /**
   * If micropayment is not approved, this can contain a rejection message, to be shown to the customer.
   */
  failure_message: any;

  /**
   * When we refund the customer, we will mark it here. TODO: What else do refunds need?
   */
  refunded_at: string;
}

export interface IApiPublicSecurityEventInfo {
  /**
   * Email address, uniquely identifies user in the system
   */
  email: string;

  /**
   * ID is a hash based on all other parameters
   */
  id: string;

  /**
   * String describing event type. Usually maps to one of EVENTS.
   */
  event_type: ISecurityEventType;

  /**
   * When was the event created
   */
  timestamp: string;

  ip_address: any;

  country_name: any;

  city_name: any;

  browser_family: any;

  os_family: any;

  /**
   * Free-form additional data that was attached
   */
  additional_data: IApiPublicSecurityEventInfoAdditionalDataItem[];
}

export interface IApiPublicSecurityEventInfoAdditionalDataItem {
  name: string;

  value: any;
}

export interface IApiPublicTradeInfo {
  /**
   * Signature uniquely identifies each trade and is derived from the involved orders.
   */
  signature: string;

  /**
   * Instrument pair for this order
   */
  pair: IPair;

  /**
   * Side of the order book. One of ORDER_SIDES, buy or sell.
   */
  side: IOrderSide;

  /**
   * Price, representing the amount of BASE instrument for 1 of QUOTE instrument.
   * Used to position limit and stop orders.
   */
  price: number;

  /**
   * The amount of QUOTE instrument user wants to buy or sell.
   */
  quantity: string;

  /**
   * Date when this order was processed by the system
   */
  timestamp: string;

  /**
   * User ID
   */
  user_id: string;

  /**
   * Side of the user's order. For example, if user's order was in order book,
   * this will be reverse from the "side" property.
   */
  user_side: IOrderSide;

  /**
   * Order ID. Auto-generated string.
   */
  order_id: string;

  /**
   * Order type. One of ORDER_TYPES.
   */
  order_type: IOrderType;

  /**
   * Fee of user's order, expressed as percent (0-100)
   */
  fee_percent: any;

  /**
   * Actual fee quantity that was taken, expressed in base currency
   */
  fee_quantity: string;

  /**
   * Total quantity gained or lost in the trade, expressed in base instrument
   */
  total: string;

  /**
   * Index at which trade was matched.
   */
  sort_index: any;
}

export interface IApiPublicTradeInfosCriteria {
  /**
   * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
   */
  sort_field: IPublicTradeInfoSortField | IPublicTradeInfoSortField[];

  /**
   * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
   */
  sort_direction: ISortDirection | ISortDirection[];

  /**
   * General search filter. Business logic dictates where will this be applied
   */
  filter: any;

  /**
   * Page to take. This is enabled in conjunction with page_size
   */
  page: number;

  /**
   * If this is set, the results will be limited to given page size
   */
  page_size: number;

  /**
   * Set to true to include soft-deleted records
   */
  include_deleted: boolean;

  /**
   * Filter by the instrument pair
   */
  pair: IPair[];

  /**
   * Filter by matcher order type
   */
  type: string;
}

export interface IApiPublicTrollboxMessage {
  id: string;

  room: ITrollboxRoom;

  nickname: string;

  message: string;

  timestamp: string;

  /**
   * Type of a user. One of ROLES
   */
  role: IRole;
}

export interface IApiQueryResultForCensoredCardPayment {
  items: IApiCensoredCardPayment[];

  page: number;

  page_size: number;

  total_records: number;

  total_pages: number;
}

export interface IApiQueryResultForCensoredDeposit {
  items: IApiCensoredDeposit[];

  page: number;

  page_size: number;

  total_records: number;

  total_pages: number;
}

export interface IApiQueryResultForCensoredManagedBuyOrder {
  items: IApiCensoredManagedBuyOrder[];

  page: number;

  page_size: number;

  total_records: number;

  total_pages: number;
}

export interface IApiQueryResultForCensoredManagedSellOrder {
  items: IApiCensoredManagedSellOrder[];

  page: number;

  page_size: number;

  total_records: number;

  total_pages: number;
}

export interface IApiQueryResultForCensoredOrder {
  items: IApiCensoredOrder[];

  page: number;

  page_size: number;

  total_records: number;

  total_pages: number;
}

export interface IApiQueryResultForCensoredWithdrawal {
  items: IApiCensoredWithdrawal[];

  page: number;

  page_size: number;

  total_records: number;

  total_pages: number;
}

export interface IApiQueryResultForPublicSecurityEventInfo {
  items: IApiPublicSecurityEventInfo[];

  page: number;

  page_size: number;

  total_records: number;

  total_pages: number;
}

export interface IApiQueryResultForPublicTradeInfo {
  items: IApiPublicTradeInfo[];

  page: number;

  page_size: number;

  total_records: number;

  total_pages: number;
}

export interface IApiQuoteRequestPayload {
  fiat_instrument: ICardPaymentFiatInstrument;

  instrument: ICardPaymentCryptoInstrument;

  requested_instrument: IInstrument;

  quantity: string;
}

export interface IApiQuoteResult {
  quote_id: string;

  fiat_instrument: string;

  fiat_quantity: number;

  instrument: string;

  quantity: number;

  valid_until: string;
}

export interface IApiRates {
  external: IApiExternalRates;

  market: IApiMarketRates;

  override: any;

  fallback: any;
}

export interface IApiRegisterPayload {
  /**
   * User email. Main identifier for customers in the system.
   */
  email: string;

  /**
   * Password for the user record.
   */
  password: string;

  /**
   * Customer's first name
   */
  first_name: string;

  /**
   * Customer's last name
   */
  last_name: string;

  /**
   * 2 letter ISO code of user's home country. We will decide which legal rules apply to customer
   * based on this country.
   */
  nationality: ICountryCode;

  /**
   * Default language for email communication and similar. Can be changed later.
   */
  language?: ILanguage;

  /**
   * Token obtained by solving captcha challenge
   */
  captcha_token?: string;
}

export interface IApiRegistrationPolicy {
  /**
   * Disable registration of new customers
   */
  disabled: boolean;

  /**
   * Reason for disabled registration
   */
  disabled_message: any;

  /**
   * List of IP-s from which registration is allowed. Useful during testing.
   * Set to empty to disable the feature. Set to empty list to disallow registrations altogether.
   */
  cidr_whitelist: any;

  country_blacklist: ICountryCode[];

  /**
   * Require captcha to be solved during registration
   */
  require_captcha: boolean;
}

export interface IApiReportDownload {
  /**
   * ID the client can call to download the generated file
   */
  id: any;

  /**
   * Name of the generated file.
   */
  file_name: any;
}

export interface IApiRequestKYC1Payload {
  /**
   * Nationality, 2 letter country code
   */
  nationality: ICountryCode;

  /**
   * First name
   */
  first_name: any;

  /**
   * Middle name
   */
  middle_name?: any;

  /**
   * Last name
   */
  last_name: any;

  /**
   * One of GENDERS-s
   */
  gender: IGender;

  /**
   * Street address, eg. Maple Street 123
   */
  residence_address: any;

  /**
   * Name of the city
   */
  residence_city: any;

  /**
   * Postal code
   */
  residence_postal_code: any;

  /**
   * Country that corresponds with the address field
   */
  residence_country: ICountryCode;

  /**
   * US state
   */
  residence_us_state?: IUSStateCode;

  /**
   * Mobile phone number
   */
  phone: string;
}

export interface IApiRequestKYC1Result {
  /**
   * Updated customer record
   */
  customer: IApiPublicCustomerInfo;

  /**
   * Frontend should send customer to this URL to do the verification
   * (possibly in iframe).
   */
  redirect_url: any;
}

export interface IApiRequestKYC2Payload {
  /**
   * Uploaded image with utility bill
   */
  image_id: any;
}

export interface IApiRequestResetPasswordTokenPayload {
  email: string;
}

export interface IApiSession {
  /**
   * Access token that uniquely identifies session
   */
  access_token: any;

  /**
   * User who owns this session
   */
  user_id: any;

  /**
   * Id of stored geo location info
   */
  geo_location_id: any;

  /**
   * What kind of geo location pinning we will use for this session.
   * One of GEO_LOCATION_PINNINGS.
   */
  geo_location_pinning: IGeoLocationPinning;

  /**
   * User agent that has created this session.
   */
  user_agent: any;

  /**
   * When was this session created
   */
  created_at: string;

  /**
   * Date when this token will expire.
   */
  expires_at: string;

  /**
   * Number of milliseconds session can be "idle" (without HTTP requests), before user is force-logged out.
   * Client should observe this value and send keep alive requests if user has requested so.
   */
  idle_timeout: number;

  /**
   * The last time the user with this session was seen at their client (eg. moving a mouse or performing some activity).
   * This will have ramifications regarding idle_timeout.
   */
  seen_at: string;

  /**
   * If session is terminated, this will be set to date when
   */
  terminated_at: string;

  /**
   * One of SESSION_TERMINATION_REASONS
   */
  termination_reason: ISessionTerminationReason;

  /**
   * Arbitrary additional details that are relevant for termination
   */
  termination_details: any;
}

export interface IApiSessionSeenPayload {
  /**
   * Access token that was seen
   */
  access_token: any;

  /**
   * How long ago was session last seen, in milliseconds
   */
  seen_ago?: number;
}

export interface IApiSessionsPayload {
  access_tokens: any[];
}

export interface IApiSetCustomerPhonePayload {
  phone: string;
}

export interface IApiSignatureSecretCriteria {
  tfa_token: string;
}

export interface IApiSystemTime {
  date: string;
}

export interface IApiTFAEnableResponse {
  /**
   * Secret key that user should store in their device
   */
  tfa_secret_key: string;
}

export interface IApiTradingOverviewReport {
  user_id: any;

  pairs: { [key: string]: IApiTradingOverviewReportItem };
}

export interface IApiTradingOverviewReportItem {
  pair: any;

  buy_count: any;

  sell_count: any;
}

/**
 * Criteria for filtering trollbox history
 */
export interface IApiTrollboxHistoryPublicCriteria {
  /**
   * Only history for the given room
   */
  room: ITrollboxRoom;

  /**
   * Limit number of returned records
   */
  limit?: number;
}

export interface IApiTrollboxMessagePayload {
  /**
   * Message content
   */
  message: string;
}

export interface IApiUser {
  /**
   * User ID
   */
  id: string;

  /**
   * Type of a user. One of ROLES
   */
  role: IRole;

  /**
   * List of admin permissions this user is given
   */
  admin_permissions: IAdminPermission[];

  /**
   * Email address, uniquely identifies user in the system
   */
  email: string;

  /**
   * Nickname to be used in chat
   */
  nickname: string;

  /**
   * User's chosen language. Defaults to EN.
   */
  language: ILanguage;

  /**
   * Keep user's session alive as long as their tab is active, even if they go away from the keyboard.
   */
  enable_keep_alive: boolean;

  /**
   * User's order confirmation settings
   */
  enable_order_confirmations: boolean;

  /**
   * Enable withdrawal confirmations
   */
  enable_withdrawal_confirmations: boolean;

  /**
   * User's order notification settings
   */
  enable_order_notifications: boolean;

  /**
   * User's geo location pinning settings
   */
  geo_location_pinning: IGeoLocationPinning;

  /**
   * If this is set to a date, user has requested email verification at given date
   */
  email_verification_requested_at: string;

  /**
   * If this is set to a date, user's email is verified
   */
  email_verified_at: string;

  /**
   * If this is set, it means user has enabled 2FA
   */
  tfa_enabled_at: string;

  /**
   * The highest level of KYC verification user has passed
   */
  kyc_level_granted: number;

  /**
   * The highest level of KYC verification user has passed for VQF (use with Managed)
   */
  kyc_level_granted_vqf: number;

  /**
   * This customer has had KYC0 back when that was a thing.
   * We no longer have any special functionality based on this,
   * but we are keeping this flag for now, for historical purposes.
   */
  kyc0_grandfathered_in: any;

  /**
   * If this is set to a date, user was banned at given date
   */
  trollbox_banned_at: string;

  /**
   * If this is set to a date, user ban expires at given date
   */
  trollbox_ban_expires_at: string;

  /**
   * The reason why user is banned
   */
  trollbox_ban_reason: string;

  created_at: string;

  updated_at: string;

  /**
   * If this is set, user won't be able to log into the exchange or do anything else
   */
  suspended_at: string;

  /**
   * NOTE: This is not worked out at all
   */
  deleted_at: string;

  /**
   * Previous email address, before it was changed to current one
   */
  old_email: string;

  nickname_updated_at: string;

  /**
   * Nickname that the user requested to be changed to
   */
  requested_nickname: any;

  /**
   * Message to send/display to the user in case a nickname has been declined
   */
  nickname_decline_reason: any;
}

export interface IApiUserEmailVerifiedEventData {
  user_id: string;

  email_verified_at: string;
}

export interface IApiUserExchangeData {
  user_pair_policy: { [key: string]: IApiUserPairPolicy };

  market_trades: IApiPublicTradeInfo[];

  user_trades: IApiPublicTradeInfo[];

  market_depth: IApiMarketDepth;

  user_orders: IApiQueryResultForCensoredOrder;

  exchange_overview: IApiTradingOverviewReport;
}

export interface IApiUserExchangeDataCriteria {
  /**
   * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
   */
  sort_field: string | string[];

  /**
   * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
   */
  sort_direction: ISortDirection | ISortDirection[];

  /**
   * Page to take. This is enabled in conjunction with page_size
   */
  page: number;

  /**
   * If this is set, the results will be limited to given page size
   */
  page_size: number;
}

export interface IApiUserInstrumentPolicy {
  /**
   * Instrument to which this config applies. If null, applies to all instruments.
   */
  instrument: string;

  /**
   * Full-form name of the instrument
   */
  name: string;

  /**
   * If true, deposits are disabled
   */
  disable_deposits: boolean;

  /**
   * Explanation why deposits are disabled. Typed in by the admin.
   */
  disable_deposits_reason: any;

  /**
   * If true, withdrawals are disabled
   */
  disable_withdrawals: boolean;

  /**
   * Explanation why withdrawals are disabled. Typed in by the admin.
   */
  disable_withdrawals_reason: any;

  /**
   * Absolute fee for a deposit. The amount in target instrument which will be reduced from the balance.
   */
  deposit_fee_abs: string;

  /**
   * Percent fee for a deposit. Percent of the total balance which will be taken. Applied after absolute fee.
   */
  deposit_fee_pct: number;

  /**
   * Absolute fee for a withdrawal. The amount in target instrument which will be reduced from the balance.
   */
  withdrawal_fee_abs: string;

  /**
   * Percent fee for a withdrawal. Percent of the total balance which will be taken. Applied after absolute fee.
   */
  withdrawal_fee_pct: number;

  /**
   * Minimal withdrawal quantity. Expressed in specific instrument, and is based on current exchange rate.
   */
  min_withdrawal: string;

  /**
   * Maximal withdrawal quantity. Expressed in specific instrument, and is based on current exchange rate.
   */
  max_withdrawal: string;

  /**
   * Use disable_deposits instead
   */
  disabled_deposits: any;

  /**
   * Use disable_withdrawals instead
   */
  disabled_withdrawals: any;

  /**
   * User to which this config applies. If null, applies to all users.
   */
  user_id: any;
}

export interface IApiUserPairPolicy {
  /**
   * Pair to which this config applies. If null, applies to all pairs.
   */
  pair: string;

  /**
   * User to which this config applies. If null, applies to all users.
   */
  user_id: any;

  /**
   * If true, given pair is disabled.
   */
  disable_orders: boolean;

  /**
   * Explanation why orders are disabled. Typed in by the admin.
   */
  disable_orders_reason: any;

  /**
   * Fee for to be taken out of trades. Presented as multiplier to be applied to either side of trade.
   */
  trade_fee: number;

  /**
   * Minimal quantity of order. Presented in BASE instrument. If buying, converted to base instrument using converter.
   */
  min_order: string;

  /**
   * Use disable_orders instead
   */
  disabled_orders: any;
}

export interface IApiUserProfilePayload {
  /**
   * User's chosen language. Defaults to EN.
   */
  language: ILanguage;

  /**
   * Nickname to be used in chat
   */
  nickname: string;

  /**
   * User's geo location pinning settings
   */
  geo_location_pinning: IGeoLocationPinning;

  /**
   * Keep user's session alive as long as their tab is active, even if they go away from the keyboard.
   */
  enable_keep_alive: boolean;

  /**
   * User's order notification settings
   */
  enable_order_notifications: boolean;

  /**
   * User's order confirmation settings
   */
  enable_order_confirmations: boolean;

  /**
   * Enable withdrawal confirmations
   */
  enable_withdrawal_confirmations: boolean;
}

export interface IApiUserSessionData {
  customer: IApiPublicCustomerInfo;

  wallet: IApiWallet;

  user_pair_policy: { [key: string]: IApiUserPairPolicy };

  user_instrument_policy: { [key: string]: IApiUserInstrumentPolicy };

  card_payment_user_config: IApiPaymentConfigForUser;

  managed_user_config: IApiManagedUserConfig;

  payment_instrument_config: IApiPaymentInstrumentConfig;

  active_instruments_orders: IApiTradingOverviewReport;
}

export interface IApiWallet {
  /**
   * User ID
   */
  user_id: string;

  /**
   * Lookup of instruments and wallet amounts
   */
  instruments: { [key: string]: IApiWalletInstrument };
}

export interface IApiWalletInstrument {
  /**
   * User ID
   */
  user_id: string;

  /**
   * Instrument
   */
  instrument: IMarketInstrument;

  /**
   * Total quantity in the wallet for this instrument
   */
  total: string;

  /**
   * The amount tied in orders, withdrawals and other actions
   */
  reserved: string;
}

/**
 * Schema to specify expected types from all emitted socket events available to web API
 */
export interface IApiWebSocketEvents {
  types: IApiWebSocketEventTypes;

  names: IWebSocketEvent;

  sectors: IWebSocketSector;
}

export interface IApiWebSocketEventTypes {
  broadcast_message: IApiPublicTrollboxMessage;

  market_depth: IApiMarketDepth;

  price_summary_24h: IApiPriceHistorySummary;

  public_trade: IApiAnonymousTradeInfo;

  user_trade: IApiPublicTradeInfo;

  order_added: IApiCensoredOrder;

  order_cancelled: IApiCensoredOrder;

  order_filled: IApiCensoredOrderFilledEventData;

  order_partially_filled: IApiCensoredOrderPartiallyFilledEventData;

  order_triggered: IApiCensoredOrder;

  wallet: IApiWallet;

  delete_session: IApiSession;

  deposit_incoming: IApiCensoredDeposit;

  deposit_completed: IApiCensoredDeposit;

  withdrawal_completed: IApiCensoredWithdrawal;

  withdrawal_confirmed: IApiCensoredWithdrawal;

  kyc_request_stalled: IApiPublicCustomerInfo;

  kyc_request_resolved: IApiCensoredKYCRequestResolvedEventData;

  card_payment_approved: IApiCensoredCardPayment;

  card_payment_declined: IApiCensoredCardPayment;

  user_suspended: IApiUser;

  nickname_resolved: IApiUser;

  external_rates_updated: IApiExternalRates;

  market_rates_updated: IApiMarketRates;

  managed_buy_order_updated: IApiManagedBuyOrder;

  managed_sell_order_updated: IApiManagedSellOrder;

  execute_email_verification: IApiUserEmailVerifiedEventData;
}

/**
 * Schema to specify type info about all the errors exposed to WebUI consumers
 */
export interface IApiWebuiErrorTypes {
  error_names: IBackendErrorName;
}

export interface IApiWebUISchemas {
  /**
   * Schema to specify expected types from all emitted socket events available to web API
   */
  socket_events: IApiWebSocketEvents;

  /**
   * Schema to specify type info about all the errors exposed to WebUI consumers
   */
  errors: IApiWebuiErrorTypes;
}

export interface IApiWithdrawalRequestPayload {
  /**
   * The withdrawal instrument.
   */
  instrument: IMarketInstrument;

  /**
   * The withdrawal quantity, as a decimal
   */
  quantity: string;

  /**
   * Whether the maximum allowed quantity should be withdrawn
   */
  auto_fill_quantity?: boolean;

  /**
   * Address where the instrument was sent
   */
  target_address: string;

  tfa_token?: string;
}

export interface IApiWithdrawalsCriteria {
  /**
   * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
   */
  sort_field: IWithdrawalSortField | IWithdrawalSortField[];

  /**
   * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
   */
  sort_direction: ISortDirection | ISortDirection[];

  /**
   * General search filter. Business logic dictates where will this be applied
   */
  filter: any;

  /**
   * Page to take. This is enabled in conjunction with page_size
   */
  page: number;

  /**
   * If this is set, the results will be limited to given page size
   */
  page_size: number;

  /**
   * Set to true to include soft-deleted records
   */
  include_deleted: boolean;

  /**
   * Only for these instruments
   */
  instruments: IInstrument[];
}

export interface IApiWithdrawalWindowReport {
  instrument: string;

  /**
   * The maxiumum amount user is allowed to withdraw in the window time span.
   */
  limit: string;

  /**
   * Limit converted to fiat currency.
   */
  limit_in_fiat: string;

  /**
   * The total amount withdrawn in the window span.
   */
  total: string;

  /**
   * How much user can withdraw till the end of the window.
   */
  remaining: string;

  /**
   * Remaining amount converted to fiat currency.
   */
  remaining_in_fiat: string;
}

// *********************************************************************************************************************

const METHOD_NAMES = [
  'deleteAuthSessions',
  'deleteAuthSessionsSpec',
  'deleteBot',
  'deleteBotSpec',
  'deleteExchangeOrder',
  'deleteExchangeOrderSpec',
  'deleteNicknameChangeRequest',
  'deleteNicknameChangeRequestSpec',
  'deleteWithdrawal',
  'deleteWithdrawalSpec',
  'getAuthLoginShouldAskCaptcha',
  'getAuthLoginShouldAskCaptchaSpec',
  'getAuthPrincipal',
  'getAuthPrincipalSpec',
  'getAuthSessions',
  'getAuthSessionsSpec',
  'getBots',
  'getBotSignatureSecret',
  'getBotSignatureSecretSpec',
  'getBotsSpec',
  'getBrokerDownloadReport',
  'getBrokerDownloadReportSpec',
  'getBrokerEstimatorData',
  'getBrokerEstimatorDataSpec',
  'getBrokerGraph',
  'getBrokerGraphSpec',
  'getBrokerMarketData',
  'getBrokerMarketDataSpec',
  'getBrokerOrdersBuy',
  'getBrokerOrdersBuySpec',
  'getBrokerOrdersSell',
  'getBrokerOrdersSellSpec',
  'getBrokerPrepareReport',
  'getBrokerPrepareReportSpec',
  'getCardPayments',
  'getCardPaymentsCsv',
  'getCardPaymentsCsvSpec',
  'getCardPaymentsSpec',
  'getCsvDownload',
  'getCsvDownloadSpec',
  'getCustomers',
  'getCustomersSpec',
  'getDeposits',
  'getDepositsCsv',
  'getDepositsCsvSpec',
  'getDepositsSpec',
  'getExchangeOrders',
  'getExchangeOrdersCsv',
  'getExchangeOrdersCsvSpec',
  'getExchangeOrdersSpec',
  'getExchangePairOrders',
  'getExchangePairOrdersSpec',
  'getExchangeReportsPriceHistory',
  'getExchangeReportsPriceHistorySpec',
  'getExchangeTrades',
  'getExchangeTradesCsv',
  'getExchangeTradesCsvSpec',
  'getExchangeTradesSpec',
  'getPasswordResetVerify',
  'getPasswordResetVerifySpec',
  'getPhoneVerificationState',
  'getPhoneVerificationStateSpec',
  'getSchemas',
  'getSchemasSpec',
  'getSecurityEventsInfos',
  'getSecurityEventsInfosCsv',
  'getSecurityEventsInfosCsvSpec',
  'getSecurityEventsInfosSpec',
  'getTradingViewChartSavedState',
  'getTradingViewChartSavedStateSpec',
  'getTrollbox',
  'getTrollboxSpec',
  'getUsersAddresses',
  'getUsersAddressesSpec',
  'getWallets',
  'getWalletsSpec',
  'getWebuiGlobalAppData',
  'getWebuiGlobalAppDataSpec',
  'getWebuiGlobalExchangeData',
  'getWebuiGlobalExchangeDataSpec',
  'getWebuiSystemTime',
  'getWebuiSystemTimeSpec',
  'getWebuiUserAppData',
  'getWebuiUserAppDataSpec',
  'getWebuiUserExchangeData',
  'getWebuiUserExchangeDataSpec',
  'getWebuiUserSessionData',
  'getWebuiUserSessionDataSpec',
  'getWithdrawals',
  'getWithdrawalsCsv',
  'getWithdrawalsCsvSpec',
  'getWithdrawalsSpec',
  'getWithdrawalsWindow',
  'getWithdrawalsWindowSpec',
  'postAuthLogin',
  'postAuthLoginSpec',
  'postAuthRenew',
  'postAuthRenewSpec',
  'postBot',
  'postBotSpec',
  'postBrokerOrdersBuy',
  'postBrokerOrdersBuySpec',
  'postBrokerOrdersSell',
  'postBrokerOrdersSellSpec',
  'postCardPayment',
  'postCardPaymentSpec',
  'postCardPaymentsQuote',
  'postCardPaymentsQuoteSpec',
  'postCustomersRegister',
  'postCustomersRegisterSpec',
  'postExchangeOrder',
  'postExchangeOrderSpec',
  'postImage',
  'postImageSpec',
  'postPasswordResetRequest',
  'postPasswordResetRequestSpec',
  'postTrollbox',
  'postTrollboxSpec',
  'postWithdrawal',
  'postWithdrawalSpec',
  'put2faConfirm',
  'put2faConfirmSpec',
  'put2faDisable',
  'put2faDisableSpec',
  'put2faEnable',
  'put2faEnableSpec',
  'putAuthLogout',
  'putAuthLogoutSpec',
  'putAuthNotifyIdleTimeout',
  'putAuthNotifyIdleTimeoutSpec',
  'putAuthNotifySeen',
  'putAuthNotifySeenSpec',
  'putBot',
  'putBotSpec',
  'putBrokerAgreeToTerm',
  'putBrokerAgreeToTermSpec',
  'putBrokerOrdersBuy',
  'putBrokerOrdersBuyCancel',
  'putBrokerOrdersBuyCancelSpec',
  'putBrokerOrdersBuySpec',
  'putBrokerOrdersSell',
  'putBrokerOrdersSellCancel',
  'putBrokerOrdersSellCancelSpec',
  'putBrokerOrdersSellSpec',
  'putCardPaymentSubmitted',
  'putCardPaymentSubmittedSpec',
  'putCustomersSof',
  'putCustomersSofSpec',
  'putEmailChange',
  'putEmailChangeSpec',
  'putEmailVerificationCode',
  'putEmailVerificationCodeSpec',
  'putEmailVerificationExecute',
  'putEmailVerificationExecuteSpec',
  'putEmailVerificationRequest',
  'putEmailVerificationRequestSpec',
  'putKycLevel1Base',
  'putKycLevel1BaseSpec',
  'putKycLevel1Submitted',
  'putKycLevel1SubmittedSpec',
  'putKycLevel1Vqf',
  'putKycLevel1VqfSpec',
  'putKycLevel2',
  'putKycLevel2Spec',
  'putPasswordChange',
  'putPasswordChangeSpec',
  'putPasswordResetExecute',
  'putPasswordResetExecuteSpec',
  'putPhoneVerificationRequest',
  'putPhoneVerificationRequestSpec',
  'putPhoneVerificationSetCustomerPhone',
  'putPhoneVerificationSetCustomerPhoneSpec',
  'putPhoneVerificationVerify',
  'putPhoneVerificationVerifySpec',
  'putTradingViewChartSavedState',
  'putTradingViewChartSavedStateSpec',
  'putUsersAddressesIssue',
  'putUsersAddressesIssueSpec',
  'putUsersUpdateProfile',
  'putUsersUpdateProfileSpec',
  'putWithdrawalsConfirm',
  'putWithdrawalsConfirmSpec',
];

export type IApiXcalibraClientRequestHandlerFunction = <T>(
  req: IApiXcalibraClientRequest
) => Promise<T>;

/**
 * Definition of the request objects we will be generating. Implementer's "makeRequest" function should know how to handle these.
 */
export interface IApiXcalibraClientRequest {
  /**
   * HTTP verb to use (eg. get, post...)
   */
  verb: string;

  /**
   * HTTP endpoint without url part and query. For example, '/api/v1/a/b/c'
   */
  endpoint: string;

  /**
   * Whether this endpoint requires authentication.
   */
  auth: boolean;

  /**
   * Name of the function on the client that will trigger this request.
   */
  method?: string;

  /**
   * Object with query data, if feasible.
   */
  query?: any;

  /**
   * Object with body data, if feasible.
   */
  body?: any;

  /**
   * Headers to be added to the request
   */
  headers?: any;

  /**
   * Request content type. Usually "json", can also be "multipart"
   */
  content_type?: string;

  /**
   * If true, response will be binary data, instead of JSON
   */
  raw_response?: boolean;

  /**
   * Internal router that will be used.
   */
  router?: string;
}

/**
 * Main client class. This is what you'll want to create to use the client. You should provide the function
 * which will actually execute requests.
 */
export class XcalibraClient {
  private readonly _makeRequest: IApiXcalibraClientRequestHandlerFunction;

  /**
   * @param makeRequest Your function which will execute requests.
   * @param bindMethods If true, methods will be bound to this client instance
   */
  constructor(makeRequest: IApiXcalibraClientRequestHandlerFunction, bindMethods = true) {
    this._makeRequest = makeRequest;

    if (typeof this._makeRequest !== 'function') {
      throw new TypeError(
        `You must instantiate XcalibraClient with a function to handle the actual requests`
      );
    }

    if (bindMethods) {
      METHOD_NAMES.forEach(name => {
        this[name] = this[name].bind(this);
      });
    }
  }

  /**
   * Terminate given list of session id-s
   */
  deleteAuthSessions(payload: Partial<IApiSessionsPayload>): Promise<{ [key: string]: boolean }> {
    const req = this.deleteAuthSessionsSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Terminate given list of session id-s
   */
  deleteAuthSessionsSpec(payload: Partial<IApiSessionsPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'delete',
      endpoint: `/api/web/v1/auth/sessions`,
      auth: true,
      method: 'deleteAuthSessions',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Soft-delete bot
   */
  deleteBot(apiKey): Promise<IApiBot> {
    const req = this.deleteBotSpec(apiKey);

    return this._makeRequest(req);
  }

  /**
   * Soft-delete bot
   */
  deleteBotSpec(apiKey): IApiXcalibraClientRequest {
    const req = {
      verb: 'delete',
      endpoint: `/api/web/v1/bots/${apiKey}`,
      auth: true,
      method: 'deleteBot',
      router: 'web',
    };

    return req;
  }

  /**
   * Cancel previously placed order
   */
  deleteExchangeOrder(pair: IPair, orderId: string): Promise<IApiCancelOrderReport> {
    const req = this.deleteExchangeOrderSpec(pair, orderId);

    return this._makeRequest(req);
  }

  /**
   * Cancel previously placed order
   */
  deleteExchangeOrderSpec(pair: IPair, orderId: string): IApiXcalibraClientRequest {
    const req = {
      verb: 'delete',
      endpoint: `/api/web/v1/exchange/${pair}/orders/${orderId}`,
      auth: true,
      method: 'deleteExchangeOrder',
      router: 'web',
    };

    return req;
  }

  /**
   * Clears the requested nickname and nickname decline reason
   */
  deleteNicknameChangeRequest(): Promise<IApiUser> {
    const req = this.deleteNicknameChangeRequestSpec();

    return this._makeRequest(req);
  }

  /**
   * Clears the requested nickname and nickname decline reason
   */
  deleteNicknameChangeRequestSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'delete',
      endpoint: `/api/web/v1/nickname/change-request`,
      auth: true,
      method: 'deleteNicknameChangeRequest',
      router: 'web',
    };

    return req;
  }

  /**
   * Cancel a withdrawal request. This can only be done before the withdrawal is taken for processing
   */
  deleteWithdrawal(withdrawalId): Promise<IApiCensoredWithdrawal> {
    const req = this.deleteWithdrawalSpec(withdrawalId);

    return this._makeRequest(req);
  }

  /**
   * Cancel a withdrawal request. This can only be done before the withdrawal is taken for processing
   */
  deleteWithdrawalSpec(withdrawalId): IApiXcalibraClientRequest {
    const req = {
      verb: 'delete',
      endpoint: `/api/web/v1/withdrawals/${withdrawalId}`,
      auth: true,
      method: 'deleteWithdrawal',
      router: 'web',
    };

    return req;
  }

  /**
   * Check if client making login request should be challenged with captcha
   */
  getAuthLoginShouldAskCaptcha() {
    const req = this.getAuthLoginShouldAskCaptchaSpec();

    return this._makeRequest(req);
  }

  /**
   * Check if client making login request should be challenged with captcha
   */
  getAuthLoginShouldAskCaptchaSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/auth/login/should-ask-captcha`,
      auth: false,
      method: 'getAuthLoginShouldAskCaptcha',
      router: 'web',
    };

    return req;
  }

  /**
   * Retrieve user's current principal based on their access token
   */
  getAuthPrincipal(): Promise<IApiPrincipal> {
    const req = this.getAuthPrincipalSpec();

    return this._makeRequest(req);
  }

  /**
   * Retrieve user's current principal based on their access token
   */
  getAuthPrincipalSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/auth/principal`,
      auth: true,
      method: 'getAuthPrincipal',
      router: 'web',
    };

    return req;
  }

  /**
   * Retrieve user's active sessions.
   */
  getAuthSessions(): Promise<IApiCensoredSessionInfo[]> {
    const req = this.getAuthSessionsSpec();

    return this._makeRequest(req);
  }

  /**
   * Retrieve user's active sessions.
   */
  getAuthSessionsSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/auth/sessions`,
      auth: true,
      method: 'getAuthSessions',
      router: 'web',
    };

    return req;
  }

  /**
   * Get list of active bots belonging to logged in user
   */
  getBots(): Promise<IApiBot[]> {
    const req = this.getBotsSpec();

    return this._makeRequest(req);
  }

  /**
   * Get signature secret for given bot. This will create a security event in some circumstances.
   */
  getBotSignatureSecret(apiKey, criteria: Partial<IApiSignatureSecretCriteria>) {
    const req = this.getBotSignatureSecretSpec(apiKey, criteria);

    return this._makeRequest(req);
  }

  /**
   * Get signature secret for given bot. This will create a security event in some circumstances.
   */
  getBotSignatureSecretSpec(
    apiKey,
    criteria: Partial<IApiSignatureSecretCriteria>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/bots/${apiKey}/signature-secret`,
      auth: true,
      method: 'getBotSignatureSecret',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Get list of active bots belonging to logged in user
   */
  getBotsSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/bots`,
      auth: true,
      method: 'getBots',
      router: 'web',
    };

    return req;
  }

  /**
   * Get report for finalized order
   */
  getBrokerDownloadReport(downloadId): Promise<string> {
    const req = this.getBrokerDownloadReportSpec(downloadId);

    return this._makeRequest(req);
  }

  /**
   * Get report for finalized order
   */
  getBrokerDownloadReportSpec(downloadId): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/broker/download-report/${downloadId}`,
      auth: false,
      method: 'getBrokerDownloadReport',
      router: 'web',
    };

    return req;
  }

  /**
   * Get price estimator data
   */
  getBrokerEstimatorData(): Promise<IApiEstimatorData> {
    const req = this.getBrokerEstimatorDataSpec();

    return this._makeRequest(req);
  }

  /**
   * Get price estimator data
   */
  getBrokerEstimatorDataSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/broker/estimator-data`,
      auth: true,
      method: 'getBrokerEstimatorData',
      router: 'web',
    };

    return req;
  }

  /**
   * Get graph data
   */
  getBrokerGraph(): Promise<IApiGraphData> {
    const req = this.getBrokerGraphSpec();

    return this._makeRequest(req);
  }

  /**
   * Get graph data
   */
  getBrokerGraphSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/broker/graph`,
      auth: true,
      method: 'getBrokerGraph',
      router: 'web',
    };

    return req;
  }

  /**
   * Get market data for supported crypto
   */
  getBrokerMarketData() {
    const req = this.getBrokerMarketDataSpec();

    return this._makeRequest(req);
  }

  /**
   * Get market data for supported crypto
   */
  getBrokerMarketDataSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/broker/market-data`,
      auth: false,
      method: 'getBrokerMarketData',
      router: 'web',
    };

    return req;
  }

  /**
   * Query user's buy orders
   */
  getBrokerOrdersBuy(
    criteria: Partial<IApiCensoredManagedBuyOrdersCriteria>
  ): Promise<IApiQueryResultForCensoredManagedBuyOrder> {
    const req = this.getBrokerOrdersBuySpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query user's buy orders
   */
  getBrokerOrdersBuySpec(
    criteria: Partial<IApiCensoredManagedBuyOrdersCriteria>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/broker/orders/buy`,
      auth: true,
      method: 'getBrokerOrdersBuy',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Query user's sell orders
   */
  getBrokerOrdersSell(
    criteria: Partial<IApiManagedSellOrdersCriteria>
  ): Promise<IApiQueryResultForCensoredManagedSellOrder> {
    const req = this.getBrokerOrdersSellSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query user's sell orders
   */
  getBrokerOrdersSellSpec(
    criteria: Partial<IApiManagedSellOrdersCriteria>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/broker/orders/sell`,
      auth: true,
      method: 'getBrokerOrdersSell',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Prepare report for download
   */
  getBrokerPrepareReport(orderId): Promise<IApiReportDownload> {
    const req = this.getBrokerPrepareReportSpec(orderId);

    return this._makeRequest(req);
  }

  /**
   * Prepare report for download
   */
  getBrokerPrepareReportSpec(orderId): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/broker/prepare-report/${orderId}`,
      auth: true,
      method: 'getBrokerPrepareReport',
      router: 'web',
    };

    return req;
  }

  /**
   * Query user's card payments
   */
  getCardPayments(
    criteria: Partial<IApiCardPaymentsCriteria>
  ): Promise<IApiQueryResultForCensoredCardPayment> {
    const req = this.getCardPaymentsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's card payments
   */
  getCardPaymentsCsv(criteria: Partial<IApiCardPaymentsCriteria>): Promise<IApiCSVDownload> {
    const req = this.getCardPaymentsCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's card payments
   */
  getCardPaymentsCsvSpec(criteria: Partial<IApiCardPaymentsCriteria>): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/card-payments.csv`,
      auth: true,
      method: 'getCardPaymentsCsv',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Query user's card payments
   */
  getCardPaymentsSpec(criteria: Partial<IApiCardPaymentsCriteria>): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/card-payments`,
      auth: true,
      method: 'getCardPayments',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Download previously generated CSV
   */
  getCsvDownload(downloadId): Promise<string> {
    const req = this.getCsvDownloadSpec(downloadId);

    return this._makeRequest(req);
  }

  /**
   * Download previously generated CSV
   */
  getCsvDownloadSpec(downloadId): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/csv/download/${downloadId}`,
      auth: false,
      method: 'getCsvDownload',
      router: 'web',
    };

    return req;
  }

  /**
   * Get customer data for logged in user.
   */
  getCustomers(): Promise<IApiPublicCustomerInfo> {
    const req = this.getCustomersSpec();

    return this._makeRequest(req);
  }

  /**
   * Get customer data for logged in user.
   */
  getCustomersSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/customers`,
      auth: true,
      method: 'getCustomers',
      router: 'web',
    };

    return req;
  }

  /**
   * Query user's deposits
   */
  getDeposits(
    criteria: Partial<IApiDepositsForCustomerCriteria>
  ): Promise<IApiQueryResultForCensoredDeposit> {
    const req = this.getDepositsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's deposits
   */
  getDepositsCsv(criteria: Partial<IApiDepositsForCustomerCriteria>): Promise<IApiCSVDownload> {
    const req = this.getDepositsCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's deposits
   */
  getDepositsCsvSpec(
    criteria: Partial<IApiDepositsForCustomerCriteria>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/deposits.csv`,
      auth: true,
      method: 'getDepositsCsv',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Query user's deposits
   */
  getDepositsSpec(criteria: Partial<IApiDepositsForCustomerCriteria>): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/deposits`,
      auth: true,
      method: 'getDeposits',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Query user's active orders
   */
  getExchangeOrders(
    criteria: Partial<IApiActiveOrdersCriteria>
  ): Promise<IApiQueryResultForCensoredOrder> {
    const req = this.getExchangeOrdersSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's active orders
   */
  getExchangeOrdersCsv(criteria: Partial<IApiActiveOrdersCriteria>): Promise<IApiCSVDownload> {
    const req = this.getExchangeOrdersCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's active orders
   */
  getExchangeOrdersCsvSpec(criteria: Partial<IApiActiveOrdersCriteria>): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/exchange/orders.csv`,
      auth: true,
      method: 'getExchangeOrdersCsv',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Query user's active orders
   */
  getExchangeOrdersSpec(criteria: Partial<IApiActiveOrdersCriteria>): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/exchange/orders`,
      auth: true,
      method: 'getExchangeOrders',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Get buy and sell order list for the logged in user
   */
  getExchangePairOrders(pair: IPair): Promise<IApiOrderBookContentForUser> {
    const req = this.getExchangePairOrdersSpec(pair);

    return this._makeRequest(req);
  }

  /**
   * Get buy and sell order list for the logged in user
   */
  getExchangePairOrdersSpec(pair: IPair): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/exchange/${pair}/orders`,
      auth: true,
      method: 'getExchangePairOrders',
      router: 'web',
    };

    return req;
  }

  /**
   * Get price history data (for OHLC chart and similar)
   */
  getExchangeReportsPriceHistory(
    criteria: Partial<IApiPriceHistoryCriteria>
  ): Promise<IApiPriceHistory[]> {
    const req = this.getExchangeReportsPriceHistorySpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get price history data (for OHLC chart and similar)
   */
  getExchangeReportsPriceHistorySpec(
    criteria: Partial<IApiPriceHistoryCriteria>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/exchange/reports/price-history`,
      auth: false,
      method: 'getExchangeReportsPriceHistory',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Query user's trades
   */
  getExchangeTrades(
    criteria: Partial<IApiPublicTradeInfosCriteria>
  ): Promise<IApiQueryResultForPublicTradeInfo> {
    const req = this.getExchangeTradesSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's trades
   */
  getExchangeTradesCsv(criteria: Partial<IApiPublicTradeInfosCriteria>): Promise<IApiCSVDownload> {
    const req = this.getExchangeTradesCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's trades
   */
  getExchangeTradesCsvSpec(
    criteria: Partial<IApiPublicTradeInfosCriteria>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/exchange/trades.csv`,
      auth: true,
      method: 'getExchangeTradesCsv',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Query user's trades
   */
  getExchangeTradesSpec(
    criteria: Partial<IApiPublicTradeInfosCriteria>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/exchange/trades`,
      auth: true,
      method: 'getExchangeTrades',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Verify password reset token. Returns user. Valid token must be provided.
   */
  getPasswordResetVerify(token): Promise<IApiUser> {
    const req = this.getPasswordResetVerifySpec(token);

    return this._makeRequest(req);
  }

  /**
   * Verify password reset token. Returns user. Valid token must be provided.
   */
  getPasswordResetVerifySpec(token): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/password/reset/verify/${token}`,
      auth: false,
      method: 'getPasswordResetVerify',
      router: 'web',
    };

    return req;
  }

  /**
   * Retrieve user's verification state for given phone number
   */
  getPhoneVerificationState(purpose, phone): Promise<IApiPhoneVerificationState> {
    const req = this.getPhoneVerificationStateSpec(purpose, phone);

    return this._makeRequest(req);
  }

  /**
   * Retrieve user's verification state for given phone number
   */
  getPhoneVerificationStateSpec(purpose, phone): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/phone-verification/state/${purpose}/${phone}`,
      auth: true,
      method: 'getPhoneVerificationState',
      router: 'web',
    };

    return req;
  }

  /**
   * Internal method to provide schemas for different backend things for use on WebUI. The request doesn't actually return anything.
   */
  getSchemas(): Promise<IApiWebUISchemas> {
    const req = this.getSchemasSpec();

    return this._makeRequest(req);
  }

  /**
   * Internal method to provide schemas for different backend things for use on WebUI. The request doesn't actually return anything.
   */
  getSchemasSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/schemas`,
      auth: true,
      method: 'getSchemas',
      router: 'web',
    };

    return req;
  }

  /**
   * Retrieve user's security events.
   */
  getSecurityEventsInfos(
    criteria: Partial<IApiCustomerSecurityEventInfosCriteria>
  ): Promise<IApiQueryResultForPublicSecurityEventInfo> {
    const req = this.getSecurityEventsInfosSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's security events
   */
  getSecurityEventsInfosCsv(
    criteria: Partial<IApiCustomerSecurityEventInfosCriteria>
  ): Promise<IApiCSVDownload> {
    const req = this.getSecurityEventsInfosCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's security events
   */
  getSecurityEventsInfosCsvSpec(
    criteria: Partial<IApiCustomerSecurityEventInfosCriteria>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/security-events/infos.csv`,
      auth: true,
      method: 'getSecurityEventsInfosCsv',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Retrieve user's security events.
   */
  getSecurityEventsInfosSpec(
    criteria: Partial<IApiCustomerSecurityEventInfosCriteria>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/security-events/infos`,
      auth: true,
      method: 'getSecurityEventsInfos',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Get saved trading view state for current user
   */
  getTradingViewChartSavedState() {
    const req = this.getTradingViewChartSavedStateSpec();

    return this._makeRequest(req);
  }

  /**
   * Get saved trading view state for current user
   */
  getTradingViewChartSavedStateSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/trading-view-chart/saved-state`,
      auth: true,
      method: 'getTradingViewChartSavedState',
      router: 'web',
    };

    return req;
  }

  /**
   * Get trollbox chat history. Frontend should call this to load message history when user first enters trollbox
   */
  getTrollbox(
    criteria: Partial<IApiTrollboxHistoryPublicCriteria>
  ): Promise<IApiPublicTrollboxMessage[]> {
    const req = this.getTrollboxSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get trollbox chat history. Frontend should call this to load message history when user first enters trollbox
   */
  getTrollboxSpec(criteria: Partial<IApiTrollboxHistoryPublicCriteria>): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/trollbox`,
      auth: false,
      method: 'getTrollbox',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * List all already issued addresses
   */
  getUsersAddresses(): Promise<IApiCensoredDepositAddress[]> {
    const req = this.getUsersAddressesSpec();

    return this._makeRequest(req);
  }

  /**
   * List all already issued addresses
   */
  getUsersAddressesSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/users/addresses`,
      auth: true,
      method: 'getUsersAddresses',
      router: 'web',
    };

    return req;
  }

  /**
   * Get wallet for logged in user
   */
  getWallets(): Promise<IApiWallet> {
    const req = this.getWalletsSpec();

    return this._makeRequest(req);
  }

  /**
   * Get wallet for logged in user
   */
  getWalletsSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/wallets`,
      auth: true,
      method: 'getWallets',
      router: 'web',
    };

    return req;
  }

  /**
   * Required data for the webui app for anonymous user
   */
  getWebuiGlobalAppData(): Promise<IApiAppData> {
    const req = this.getWebuiGlobalAppDataSpec();

    return this._makeRequest(req);
  }

  /**
   * Required data for the webui app for anonymous user
   */
  getWebuiGlobalAppDataSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/webui/global-app-data`,
      auth: false,
      method: 'getWebuiGlobalAppData',
      router: 'web',
    };

    return req;
  }

  /**
   * Required data for the webui exchange for anonymous user
   */
  getWebuiGlobalExchangeData(pair: IPair): Promise<IApiGlobalExchangeData> {
    const req = this.getWebuiGlobalExchangeDataSpec(pair);

    return this._makeRequest(req);
  }

  /**
   * Required data for the webui exchange for anonymous user
   */
  getWebuiGlobalExchangeDataSpec(pair: IPair): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/webui/${pair}/global-exchange-data`,
      auth: false,
      method: 'getWebuiGlobalExchangeData',
      router: 'web',
    };

    return req;
  }

  /**
   * Current system time
   */
  getWebuiSystemTime(): Promise<IApiSystemTime> {
    const req = this.getWebuiSystemTimeSpec();

    return this._makeRequest(req);
  }

  /**
   * Current system time
   */
  getWebuiSystemTimeSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/webui/system-time`,
      auth: false,
      method: 'getWebuiSystemTime',
      router: 'web',
    };

    return req;
  }

  /**
   * Required data for the webui app for logged in user
   */
  getWebuiUserAppData(): Promise<IApiAppData> {
    const req = this.getWebuiUserAppDataSpec();

    return this._makeRequest(req);
  }

  /**
   * Required data for the webui app for logged in user
   */
  getWebuiUserAppDataSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/webui/user-app-data`,
      auth: true,
      method: 'getWebuiUserAppData',
      router: 'web',
    };

    return req;
  }

  /**
   * Required data for the webui exchange for logged in user
   */
  getWebuiUserExchangeData(
    pair: IPair,
    criteria: Partial<IApiUserExchangeDataCriteria>
  ): Promise<IApiUserExchangeData> {
    const req = this.getWebuiUserExchangeDataSpec(pair, criteria);

    return this._makeRequest(req);
  }

  /**
   * Required data for the webui exchange for logged in user
   */
  getWebuiUserExchangeDataSpec(
    pair: IPair,
    criteria: Partial<IApiUserExchangeDataCriteria>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/webui/${pair}/user-exchange-data`,
      auth: true,
      method: 'getWebuiUserExchangeData',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Required session user data for the webui app
   */
  getWebuiUserSessionData(): Promise<IApiUserSessionData> {
    const req = this.getWebuiUserSessionDataSpec();

    return this._makeRequest(req);
  }

  /**
   * Required session user data for the webui app
   */
  getWebuiUserSessionDataSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/webui/user-session-data`,
      auth: true,
      method: 'getWebuiUserSessionData',
      router: 'web',
    };

    return req;
  }

  /**
   * Query user's withdrawals
   */
  getWithdrawals(
    criteria: Partial<IApiWithdrawalsCriteria>
  ): Promise<IApiQueryResultForCensoredWithdrawal> {
    const req = this.getWithdrawalsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's withdrawals
   */
  getWithdrawalsCsv(criteria: Partial<IApiWithdrawalsCriteria>): Promise<IApiCSVDownload> {
    const req = this.getWithdrawalsCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for user's withdrawals
   */
  getWithdrawalsCsvSpec(criteria: Partial<IApiWithdrawalsCriteria>): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/withdrawals.csv`,
      auth: true,
      method: 'getWithdrawalsCsv',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Query user's withdrawals
   */
  getWithdrawalsSpec(criteria: Partial<IApiWithdrawalsCriteria>): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/withdrawals`,
      auth: true,
      method: 'getWithdrawals',
      router: 'web',
      query: criteria,
    };

    return req;
  }

  /**
   * Get report for user's current window withdrawals in a given instrument
   */
  getWithdrawalsWindow(instrument: IInstrument): Promise<IApiWithdrawalWindowReport> {
    const req = this.getWithdrawalsWindowSpec(instrument);

    return this._makeRequest(req);
  }

  /**
   * Get report for user's current window withdrawals in a given instrument
   */
  getWithdrawalsWindowSpec(instrument: IInstrument): IApiXcalibraClientRequest {
    const req = {
      verb: 'get',
      endpoint: `/api/web/v1/withdrawals/window/${instrument}`,
      auth: true,
      method: 'getWithdrawalsWindow',
      router: 'web',
    };

    return req;
  }

  /**
   * Login using provided email and password
   */
  postAuthLogin(payload: Partial<IApiLogInPayload>): Promise<IApiPrincipal> {
    const req = this.postAuthLoginSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Login using provided email and password
   */
  postAuthLoginSpec(payload: Partial<IApiLogInPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/auth/login`,
      auth: false,
      method: 'postAuthLogin',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Renew session. Frontend should call this before current session token expires, so it can get a new one.
   */
  postAuthRenew(): Promise<IApiSession> {
    const req = this.postAuthRenewSpec();

    return this._makeRequest(req);
  }

  /**
   * Renew session. Frontend should call this before current session token expires, so it can get a new one.
   */
  postAuthRenewSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/auth/renew`,
      auth: true,
      method: 'postAuthRenew',
      router: 'web',
    };

    return req;
  }

  /**
   * Create a new bot. API key and secret are automatically generated
   */
  postBot(payload: Partial<IApiBotPayload>): Promise<IApiBot> {
    const req = this.postBotSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Create a new bot. API key and secret are automatically generated
   */
  postBotSpec(payload: Partial<IApiBotPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/bots`,
      auth: true,
      method: 'postBot',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Create a new buy order request
   */
  postBrokerOrdersBuy(
    payload: Partial<IApiManagedBuyOrderRequestPayload>
  ): Promise<IApiManagedBuyOrder> {
    const req = this.postBrokerOrdersBuySpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Create a new buy order request
   */
  postBrokerOrdersBuySpec(
    payload: Partial<IApiManagedBuyOrderRequestPayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/broker/orders/buy`,
      auth: true,
      method: 'postBrokerOrdersBuy',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Create a new sell order request
   */
  postBrokerOrdersSell(
    payload: Partial<IApiManagedSellOrderRequestPayload>
  ): Promise<IApiCensoredManagedSellOrder> {
    const req = this.postBrokerOrdersSellSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Create a new sell order request
   */
  postBrokerOrdersSellSpec(
    payload: Partial<IApiManagedSellOrderRequestPayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/broker/orders/sell`,
      auth: true,
      method: 'postBrokerOrdersSell',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Request new payment
   */
  postCardPayment(payload: Partial<IApiPaymentRequestPayload>): Promise<IApiPaymentResult> {
    const req = this.postCardPaymentSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Request new payment
   */
  postCardPaymentSpec(payload: Partial<IApiPaymentRequestPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/card-payments`,
      auth: true,
      method: 'postCardPayment',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Get estimated price and unique ID to mark the specific quote.
   */
  postCardPaymentsQuote(payload: Partial<IApiQuoteRequestPayload>): Promise<IApiQuoteResult> {
    const req = this.postCardPaymentsQuoteSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Get estimated price and unique ID to mark the specific quote.
   */
  postCardPaymentsQuoteSpec(payload: Partial<IApiQuoteRequestPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/card-payments/quote`,
      auth: true,
      method: 'postCardPaymentsQuote',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Register a new user. Email must be unique in the system. All fields except language are mandatory
   */
  postCustomersRegister(payload: Partial<IApiRegisterPayload>): Promise<IApiPrincipal> {
    const req = this.postCustomersRegisterSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Register a new user. Email must be unique in the system. All fields except language are mandatory
   */
  postCustomersRegisterSpec(payload: Partial<IApiRegisterPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/customers/register`,
      auth: false,
      method: 'postCustomersRegister',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Place a new order
   */
  postExchangeOrder(payload: Partial<IApiOrderPayload>): Promise<IApiPlaceOrderReport> {
    const req = this.postExchangeOrderSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Place a new order
   */
  postExchangeOrderSpec(payload: Partial<IApiOrderPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/exchange/orders`,
      auth: true,
      method: 'postExchangeOrder',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Upload one or more images into the image store. Returns an array of image objects. Client can use the generated ID-s to attach to other requests
   */
  postImage(payload: Partial<IApiImageUploadBody>): Promise<IApiImage[]> {
    const req = this.postImageSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Upload one or more images into the image store. Returns an array of image objects. Client can use the generated ID-s to attach to other requests
   */
  postImageSpec(payload: Partial<IApiImageUploadBody>): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/images`,
      auth: true,
      method: 'postImage',
      router: 'web',
      content_type: 'multipart',
      body: payload,
    };

    return req;
  }

  /**
   * Send email with password reset token. Valid user email address must be provided.
   */
  postPasswordResetRequest(payload: Partial<IApiRequestResetPasswordTokenPayload>) {
    const req = this.postPasswordResetRequestSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Send email with password reset token. Valid user email address must be provided.
   */
  postPasswordResetRequestSpec(
    payload: Partial<IApiRequestResetPasswordTokenPayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/password/reset/request`,
      auth: false,
      method: 'postPasswordResetRequest',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Submit trollbox message. Frontend should call this to send new messages.
   */
  postTrollbox(room: ITrollboxRoom, payload: Partial<IApiTrollboxMessagePayload>) {
    const req = this.postTrollboxSpec(room, payload);

    return this._makeRequest(req);
  }

  /**
   * Submit trollbox message. Frontend should call this to send new messages.
   */
  postTrollboxSpec(
    room: ITrollboxRoom,
    payload: Partial<IApiTrollboxMessagePayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/trollbox/${room}`,
      auth: true,
      method: 'postTrollbox',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Create a withdrawal request
   */
  postWithdrawal(payload: Partial<IApiWithdrawalRequestPayload>): Promise<IApiCensoredWithdrawal> {
    const req = this.postWithdrawalSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Create a withdrawal request
   */
  postWithdrawalSpec(payload: Partial<IApiWithdrawalRequestPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'post',
      endpoint: `/api/web/v1/withdrawals`,
      auth: true,
      method: 'postWithdrawal',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Finish 2FA process for a user account. Valid token must be provided.
   */
  put2faConfirm(payload: Partial<object>): Promise<IApiUser> {
    const req = this.put2faConfirmSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Finish 2FA process for a user account. Valid token must be provided.
   */
  put2faConfirmSpec(payload: Partial<object>): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/2fa/confirm`,
      auth: true,
      method: 'put2faConfirm',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Disable 2FA for a user account. Valid token must be provided.
   */
  put2faDisable(payload: Partial<object>): Promise<IApiUser> {
    const req = this.put2faDisableSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Disable 2FA for a user account. Valid token must be provided.
   */
  put2faDisableSpec(payload: Partial<object>): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/2fa/disable`,
      auth: true,
      method: 'put2faDisable',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Start a process to enable 2FA for a user account
   */
  put2faEnable(): Promise<IApiTFAEnableResponse> {
    const req = this.put2faEnableSpec();

    return this._makeRequest(req);
  }

  /**
   * Start a process to enable 2FA for a user account
   */
  put2faEnableSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/2fa/enable`,
      auth: true,
      method: 'put2faEnable',
      router: 'web',
    };

    return req;
  }

  /**
   * User-initiated logout action. Access token will be terminated with the "logout" reason.
   */
  putAuthLogout() {
    const req = this.putAuthLogoutSpec();

    return this._makeRequest(req);
  }

  /**
   * User-initiated logout action. Access token will be terminated with the "logout" reason.
   */
  putAuthLogoutSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/auth/logout`,
      auth: true,
      method: 'putAuthLogout',
      router: 'web',
    };

    return req;
  }

  /**
   * Clients should call this when idle timeout expires. This is informational only, and will only speed up what the backend will do anyway.
   */
  putAuthNotifyIdleTimeout() {
    const req = this.putAuthNotifyIdleTimeoutSpec();

    return this._makeRequest(req);
  }

  /**
   * Clients should call this when idle timeout expires. This is informational only, and will only speed up what the backend will do anyway.
   */
  putAuthNotifyIdleTimeoutSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/auth/notify-idle-timeout`,
      auth: true,
      method: 'putAuthNotifyIdleTimeout',
      router: 'web',
    };

    return req;
  }

  /**
   * Tell the server when was the holder of access token in the header last seen. By "seen", we mean any activity in the app, eg. moving a mouse. Time is submitted in "X ms ago" format.
   */
  putAuthNotifySeen(payload: Partial<IApiSessionSeenPayload>): Promise<IApiSession> {
    const req = this.putAuthNotifySeenSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Tell the server when was the holder of access token in the header last seen. By "seen", we mean any activity in the app, eg. moving a mouse. Time is submitted in "X ms ago" format.
   */
  putAuthNotifySeenSpec(payload: Partial<IApiSessionSeenPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/auth/notify-seen`,
      auth: true,
      method: 'putAuthNotifySeen',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Update bot
   */
  putBot(apiKey, payload: Partial<IApiBotPayload>): Promise<IApiBot> {
    const req = this.putBotSpec(apiKey, payload);

    return this._makeRequest(req);
  }

  /**
   * Update bot
   */
  putBotSpec(apiKey, payload: Partial<IApiBotPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/bots/${apiKey}`,
      auth: true,
      method: 'putBot',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Agree to Xcalibra Managed Terms of Service. We will check for a valid SMS agreement before enrolling the account. Returns a config the client can use to update access rights.
   */
  putBrokerAgreeToTerm(): Promise<IApiManagedUserConfig> {
    const req = this.putBrokerAgreeToTermSpec();

    return this._makeRequest(req);
  }

  /**
   * Agree to Xcalibra Managed Terms of Service. We will check for a valid SMS agreement before enrolling the account. Returns a config the client can use to update access rights.
   */
  putBrokerAgreeToTermSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/broker/agree-to-terms`,
      auth: true,
      method: 'putBrokerAgreeToTerm',
      router: 'web',
    };

    return req;
  }

  /**
   * Update buy order
   */
  putBrokerOrdersBuy(
    orderId,
    payload: Partial<IApiManagedBuyOrderRequestPayload>
  ): Promise<IApiManagedBuyOrder> {
    const req = this.putBrokerOrdersBuySpec(orderId, payload);

    return this._makeRequest(req);
  }

  /**
   * Cancel buy order
   */
  putBrokerOrdersBuyCancel(orderId): Promise<IApiManagedBuyOrder> {
    const req = this.putBrokerOrdersBuyCancelSpec(orderId);

    return this._makeRequest(req);
  }

  /**
   * Cancel buy order
   */
  putBrokerOrdersBuyCancelSpec(orderId): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/broker/orders/buy/${orderId}/cancel`,
      auth: true,
      method: 'putBrokerOrdersBuyCancel',
      router: 'web',
    };

    return req;
  }

  /**
   * Update buy order
   */
  putBrokerOrdersBuySpec(
    orderId,
    payload: Partial<IApiManagedBuyOrderRequestPayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/broker/orders/buy/${orderId}`,
      auth: true,
      method: 'putBrokerOrdersBuy',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Update sell order
   */
  putBrokerOrdersSell(
    orderId,
    payload: Partial<IApiManagedSellOrderRequestPayload>
  ): Promise<IApiManagedSellOrder> {
    const req = this.putBrokerOrdersSellSpec(orderId, payload);

    return this._makeRequest(req);
  }

  /**
   * Cancel sell order
   */
  putBrokerOrdersSellCancel(orderId): Promise<IApiCensoredManagedSellOrder> {
    const req = this.putBrokerOrdersSellCancelSpec(orderId);

    return this._makeRequest(req);
  }

  /**
   * Cancel sell order
   */
  putBrokerOrdersSellCancelSpec(orderId): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/broker/orders/sell/${orderId}/cancel`,
      auth: true,
      method: 'putBrokerOrdersSellCancel',
      router: 'web',
    };

    return req;
  }

  /**
   * Update sell order
   */
  putBrokerOrdersSellSpec(
    orderId,
    payload: Partial<IApiManagedSellOrderRequestPayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/broker/orders/sell/${orderId}`,
      auth: true,
      method: 'putBrokerOrdersSell',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Mark payment request user flow completed
   */
  putCardPaymentSubmitted(paymentId) {
    const req = this.putCardPaymentSubmittedSpec(paymentId);

    return this._makeRequest(req);
  }

  /**
   * Mark payment request user flow completed
   */
  putCardPaymentSubmittedSpec(paymentId): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/card-payments/${paymentId}/submitted`,
      auth: false,
      method: 'putCardPaymentSubmitted',
      router: 'web',
    };

    return req;
  }

  /**
   * Update customer's source of funds. Can only be done during VQF KYC3.
   */
  putCustomersSof(
    payload: Partial<IApiCustomerSourceOfFundsPayload>
  ): Promise<IApiPublicCustomerInfo> {
    const req = this.putCustomersSofSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Update customer's source of funds. Can only be done during VQF KYC3.
   */
  putCustomersSofSpec(
    payload: Partial<IApiCustomerSourceOfFundsPayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/customers/sof`,
      auth: true,
      method: 'putCustomersSof',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Change email
   */
  putEmailChange(payload: Partial<IApiChangeEmailPayload>) {
    const req = this.putEmailChangeSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Change email
   */
  putEmailChangeSpec(payload: Partial<IApiChangeEmailPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/email/change`,
      auth: true,
      method: 'putEmailChange',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Submit email verification code. If request returns 200, the email can be considered verified. Otherwise, error is returned.
   */
  putEmailVerificationCode(payload: Partial<IApiEmailVerificationCodePayload>) {
    const req = this.putEmailVerificationCodeSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Submit email verification code. If request returns 200, the email can be considered verified. Otherwise, error is returned.
   */
  putEmailVerificationCodeSpec(
    payload: Partial<IApiEmailVerificationCodePayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/email/verification/code`,
      auth: true,
      method: 'putEmailVerificationCode',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Finish email verification process. Valid token must be provided.
   */
  putEmailVerificationExecute(token): Promise<IApiEmailVerificationResult> {
    const req = this.putEmailVerificationExecuteSpec(token);

    return this._makeRequest(req);
  }

  /**
   * Finish email verification process. Valid token must be provided.
   */
  putEmailVerificationExecuteSpec(token): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/email/verification/execute/${token}`,
      auth: false,
      method: 'putEmailVerificationExecute',
      router: 'web',
    };

    return req;
  }

  /**
   * Request email verification email to be sent with a fresh token
   */
  putEmailVerificationRequest(): Promise<IApiUser> {
    const req = this.putEmailVerificationRequestSpec();

    return this._makeRequest(req);
  }

  /**
   * Request email verification email to be sent with a fresh token
   */
  putEmailVerificationRequestSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/email/verification/request`,
      auth: true,
      method: 'putEmailVerificationRequest',
      router: 'web',
    };

    return req;
  }

  /**
   * Request KYC level 1. Request should contain customer details to update. Response will include updated PublicCustomerInfo record and redirect_url, which client should use to kick off KYC document verification. This URL might be reused between requests.
   */
  putKycLevel1Base(payload: Partial<IApiRequestKYC1Payload>): Promise<IApiRequestKYC1Result> {
    const req = this.putKycLevel1BaseSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Request KYC level 1. Request should contain customer details to update. Response will include updated PublicCustomerInfo record and redirect_url, which client should use to kick off KYC document verification. This URL might be reused between requests.
   */
  putKycLevel1BaseSpec(payload: Partial<IApiRequestKYC1Payload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/kyc/level1/base`,
      auth: true,
      method: 'putKycLevel1Base',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Notify the server verification has been submitted
   */
  putKycLevel1Submitted(verificationId): Promise<boolean> {
    const req = this.putKycLevel1SubmittedSpec(verificationId);

    return this._makeRequest(req);
  }

  /**
   * Notify the server verification has been submitted
   */
  putKycLevel1SubmittedSpec(verificationId): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/kyc/level1/submitted/${verificationId}`,
      auth: true,
      method: 'putKycLevel1Submitted',
      router: 'web',
    };

    return req;
  }

  /**
   * Request VQF KYC level 1. This leaves the customer in read only state, and requires that the base KYC 1 and 2 are already obtained. The rest acts the same way as /level1
   */
  putKycLevel1Vqf(): Promise<IApiRequestKYC1Result> {
    const req = this.putKycLevel1VqfSpec();

    return this._makeRequest(req);
  }

  /**
   * Request VQF KYC level 1. This leaves the customer in read only state, and requires that the base KYC 1 and 2 are already obtained. The rest acts the same way as /level1
   */
  putKycLevel1VqfSpec(): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/kyc/level1/vqf`,
      auth: true,
      method: 'putKycLevel1Vqf',
      router: 'web',
    };

    return req;
  }

  /**
   * Request KYC level 2. Request should contain method and additional details, depending on the method. Not all methods are available to all countries
   */
  putKycLevel2(payload: Partial<IApiRequestKYC2Payload>): Promise<IApiPublicCustomerInfo> {
    const req = this.putKycLevel2Spec(payload);

    return this._makeRequest(req);
  }

  /**
   * Request KYC level 2. Request should contain method and additional details, depending on the method. Not all methods are available to all countries
   */
  putKycLevel2Spec(payload: Partial<IApiRequestKYC2Payload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/kyc/level2`,
      auth: true,
      method: 'putKycLevel2',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Change password
   */
  putPasswordChange(payload: Partial<IApiChangePasswordPayload>): Promise<boolean> {
    const req = this.putPasswordChangeSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Change password
   */
  putPasswordChangeSpec(payload: Partial<IApiChangePasswordPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/password/change`,
      auth: true,
      method: 'putPasswordChange',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Execute reset password. Need to provide new password. User will be logged out of all sessions
   */
  putPasswordResetExecute(payload: Partial<IApiExecuteResetPasswordPayload>) {
    const req = this.putPasswordResetExecuteSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Execute reset password. Need to provide new password. User will be logged out of all sessions
   */
  putPasswordResetExecuteSpec(
    payload: Partial<IApiExecuteResetPasswordPayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/password/reset/execute`,
      auth: false,
      method: 'putPasswordResetExecute',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Request new phone verification SMS to be sent to submitted phone number.
   */
  putPhoneVerificationRequest(
    payload: Partial<IApiPhoneVerificationRequestPayload>
  ): Promise<IApiPhoneVerificationState> {
    const req = this.putPhoneVerificationRequestSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Request new phone verification SMS to be sent to submitted phone number.
   */
  putPhoneVerificationRequestSpec(
    payload: Partial<IApiPhoneVerificationRequestPayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/phone-verification/request`,
      auth: true,
      method: 'putPhoneVerificationRequest',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Submit verified phone number for customer account.
   */
  putPhoneVerificationSetCustomerPhone(
    payload: Partial<IApiSetCustomerPhonePayload>
  ): Promise<IApiPublicCustomerInfo> {
    const req = this.putPhoneVerificationSetCustomerPhoneSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Submit verified phone number for customer account.
   */
  putPhoneVerificationSetCustomerPhoneSpec(
    payload: Partial<IApiSetCustomerPhonePayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/phone-verification/set-customer-phone`,
      auth: true,
      method: 'putPhoneVerificationSetCustomerPhone',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Submit phone verification code. If request returns 200, the phone can be considered verified. Otherwise, error is returned.
   */
  putPhoneVerificationVerify(payload: Partial<IApiPhoneVerificationCodePayload>) {
    const req = this.putPhoneVerificationVerifySpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Submit phone verification code. If request returns 200, the phone can be considered verified. Otherwise, error is returned.
   */
  putPhoneVerificationVerifySpec(
    payload: Partial<IApiPhoneVerificationCodePayload>
  ): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/phone-verification/verify`,
      auth: true,
      method: 'putPhoneVerificationVerify',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Save trading view state
   */
  putTradingViewChartSavedState(payload: Partial<object>) {
    const req = this.putTradingViewChartSavedStateSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Save trading view state
   */
  putTradingViewChartSavedStateSpec(payload: Partial<object>): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/trading-view-chart/saved-state`,
      auth: true,
      method: 'putTradingViewChartSavedState',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Request an address to be issued for a particular instrument
   */
  putUsersAddressesIssue(
    payload: Partial<IApiIssueAddressPayload>
  ): Promise<IApiCensoredDepositAddress> {
    const req = this.putUsersAddressesIssueSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Request an address to be issued for a particular instrument
   */
  putUsersAddressesIssueSpec(payload: Partial<IApiIssueAddressPayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/users/addresses/issue`,
      auth: true,
      method: 'putUsersAddressesIssue',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Update user profile, this should be called based on the setting page actions
   */
  putUsersUpdateProfile(payload: Partial<IApiUserProfilePayload>): Promise<IApiUser> {
    const req = this.putUsersUpdateProfileSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Update user profile, this should be called based on the setting page actions
   */
  putUsersUpdateProfileSpec(payload: Partial<IApiUserProfilePayload>): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/users/update-profile`,
      auth: true,
      method: 'putUsersUpdateProfile',
      router: 'web',
      content_type: 'json',
      body: payload,
    };

    return req;
  }

  /**
   * Confirm a withdrawal request
   */
  putWithdrawalsConfirm(token): Promise<IApiCensoredWithdrawal> {
    const req = this.putWithdrawalsConfirmSpec(token);

    return this._makeRequest(req);
  }

  /**
   * Confirm a withdrawal request
   */
  putWithdrawalsConfirmSpec(token): IApiXcalibraClientRequest {
    const req = {
      verb: 'put',
      endpoint: `/api/web/v1/withdrawals/confirm/${token}`,
      auth: false,
      method: 'putWithdrawalsConfirm',
      router: 'web',
    };

    return req;
  }
}

